import {ChangeDetectorRef, Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {removeNavBarElementService} from './removeNavBarElement.service';
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";

/**
 * The root application component
 */
@Component({

  selector: 'app-root',
  styles: [`
    body {
    }
    .main {
      margin-bottom: 100px;
      overflow-y: scroll;
    }

    .attiva {
      display: inline;
    }

    .disattiva {
      display: none;
    }
    .screen-body{
      background-color: #fbfbfb;
      padding-right: 0px;
      padding-left: 0px;
    }
    @media screen and (min-width: 760px){
      .screen-body{
        margin-left: 20%;
        margin-right: 20%;
      }
    }
  ` ],
  template: `
    <body style='background-color: #fbfbfb'>
      <div class="screen-body"  >
        <router-outlet ></router-outlet>
      </div>

      <div style='background-color: #fbfbfb'
           [(ngModel)]="this.attiva"
           name="fieldName" ngDefaultControl
           id="navBarrr"
      >
        <navbar></navbar>
      </div>
    </body>
  `
})
export class AppComponent {

  disattivate = [
    'menu',
    '/menuCard/scontrini',
    '/scontrini/dettaglioScontrino',
    '/login',
    '/sign-in',
  ];

  attiva = false;

  constructor( private router: Router,
               private RemoveNavBarElementService: removeNavBarElementService,
               private changeDetection: ChangeDetectorRef,
               private breakpointObserver: BreakpointObserver
  ) {


    /*
    this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
      if (result.matches) {
        // hide stuff
      } else {
        window.location.href = 'http://lagolavalletta.it/';
        // show stuff
      }
    });

     */


    this.attiva = false;


    router.events.subscribe((val) => {
      // see also
      console.log('cambiato route');
      console.log(val);
      if (val instanceof NavigationEnd) {
        if (val.url !== undefined) {
          if (this.disattivate.indexOf(val.url) > -1) {
            const element3 = document.getElementById('navBarrr');
            element3.classList.add('disattiva');
            element3.classList.remove('attiva');
          } else {
            const element3 = document.getElementById('navBarrr');
            element3.classList.add('attiva');
            element3.classList.remove('disattiva');
          }

          if (val.url.includes('menu')) {
            {
              const element3 = document.getElementById('navBarrr');
              element3.classList.add('disattiva');
              element3.classList.remove('attiva');
            }
          }

          if (val.url.includes('pagamento')) {
            {
              const element3 = document.getElementById('navBarrr');
              element3.classList.add('disattiva');
              element3.classList.remove('attiva');
            }
          }

          if (val.url.includes('carrello')) {
            {
              const element3 = document.getElementById('navBarrr');
              element3.classList.add('disattiva');
              element3.classList.remove('attiva');
            }
          }
        }
      }


    });

    // this.RemoveNavBarElementService.updateResultList(this.attiva);

    this.RemoveNavBarElementService.resultList$
      .subscribe(resultList => {
            this.attiva = resultList;
            console.log('This is the updated resultList: ', resultList);
      });





  }

}
