import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {MenuService} from "../../shared/services/firestore/menu.service";
import {UserDataService} from "../../shared/services/firestore/userData.service";
import {firebase} from "@firebase/app";
import {UserModel} from '../../shared/models/user.model';
import {User} from '../../shared/services/user';

interface Allergeni {
  allergene: string;
  selezionato: boolean;
}

@Component({
  selector: 'app-categorie',
  templateUrl: './preferenze-alimentari.html',
  styleUrls: ['./preferenze-alimentari.css'],
  providers: [
    MenuService,
    {
      provide: 'raccolta',
      useValue: 'dati',
    },
    UserDataService
  ],
})

export class PreferenzeAlimentari implements OnInit {

  items = [
    'Item 0',
    'Item 1',
    'Item 2',
    'Item 3',
    'Item 4',
    'Item 5',
    'Item 6',
    'Item 7',
  ];

  allergeni2 = new Array();
  allergeni: Allergeni[];

  allergeniFiltrati: Allergeni[];

  allergeniUser = new Array();

  user: User;
  userDaSalvare: User;

  immagineProfilo = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FGroup_3_cc.png?alt=media&token=0d4a5c5a-3784-4206-aa5e-28e4a669032a';

  nome = 'Marco Polo';

  luogo = 'Visualizza Profilo';

  prezzo = 93263269631;

  ricerca = '';

  constructor(private router: Router,
              private _location: Location,
              private menuService: MenuService,
              private userDataService: UserDataService
  ) {
  }

  ngOnInit(): void {

    firebase.auth().onAuthStateChanged((user) => {
      console.log("ciaociaociao");
      if (user) {

        // User is signed in.
        this.retriveAllergeni(user.uid);

      } else {
        // No user is signed in.
      }
    });



  }

  valuechange(newValue) {
    console.log(this.ricerca);
    this.allergeniFiltrati = this.filterByValue(this.allergeni, newValue);
    // this.scontrini.filter(t => t.nome === newValue);

  }

  filterByValue(array, stringa) {
    console.log('filtro: ', array, stringa);

    return array.filter(o => Object.keys(o).some(k => o[k].toString().toLowerCase().includes(stringa.toLowerCase())));

  }



  dropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.items,
      event.previousIndex,
      event.currentIndex
    );
  }

  clicco2(): void {
    this._location.back();
    // this.router.navigate(['/tavoli']);
  }

  cliccoAllergene(index: any): void {

    this.allergeniFiltrati[index].selezionato = !this.allergeniFiltrati[index].selezionato;
    if (this.allergeniFiltrati[index].selezionato === false) {
      console.log('Clicco allergeni user false');
      this.allergeniUser = this.allergeniUser.filter(e => e !== this.allergeniFiltrati[index].allergene); // will return ['A', 'C']
    }
    else {
      console.log('Clicco allergeni user true');

      this.allergeniUser.push(this.allergeniFiltrati[index].allergene);
    }
    this.salva();
  }

  salva(): void{
    console.log('Salva allergeni user: ', this.allergeniUser);

    this.user.allergeni = this.allergeniUser;
    this.userDataService.delete(this.user.uid);

    // this.menuService.create(this.menuCard, this.ristorante);

    console.log('user da salvare: ', this.user);

         const userDasalvareSalva = {

      uid: this.user.uid,
      email: this.user.email,
      displayName: this.user.displayName,
           address: this.user.address,
      photoURL: this.user.photoURL,
      emailVerified: this.user.emailVerified,
      allergeni: this.allergeniUser,
      scontrini: this.user.scontrini,
      carrello: this.user.carrello,

    };

    console.log('user da salvare salva: ', userDasalvareSalva);


    this.userDataService.create(userDasalvareSalva, this.user.uid);

    // this.router.navigate(['/menuCard']);
  }


  retriveAllergeni(user?: any): void {

    this.menuService.getDoc('allergeni').get().subscribe(
      data => {
        console.log('ho trovato: ', data.data());
        if (data.data() !== undefined) {

          this.allergeni = [];

          data.data().allergeni.forEach((value, index) => {


            const valore = {
              allergene: value,
              selezionato: false,
            };

            this.allergeni.push(valore);

          });

          console.log('allergeni: ', this.allergeni);


          this.userDataService.getDoc(user).get().subscribe(
            dataUser => {
              console.log('ho trovato: ', dataUser.data());
              if (dataUser.data() !== undefined) {
                this.user = dataUser.data();
                console.log('ho trovato user: ', this.user);
                this.allergeniUser = dataUser.data().allergeni;
                console.log('allergeni dell utente: ', this.allergeniUser);

                // this.menuCard = data.data();
              }


              this.allergeni.forEach((value, index) => {

                console.log('dentro ad allergeni: ', value);

                this.allergeniUser.forEach((allergeneUser, indexUser) => {
                    console.log('dentro ad allergeniUser: ', allergeneUser);

                    if (value.allergene === allergeneUser) {

                      this.allergeni[index].selezionato = true;

                    }


                  }
                );
              });

              this.allergeniFiltrati = this.allergeni;

              console.log('allergeni filtrati: ', this.allergeniFiltrati);

            }
          );

          // this.menuCard = data.data();
        }
      }
    );





  }
}
