
import { auth } from 'firebase/firebase-auth';
import { Injectable, NgZone } from '@angular/core';
import { User } from './user';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data

  constructor(
      public afs: AngularFirestore,   // Inject Firestore service
      public afAuth: AngularFireAuth, // Inject Firebase auth service
      public router: Router,
      public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    // Save user data in localstorage when logged in and set up null when logged out
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    });
  }

  // Sign in with email/password
  SignIn(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
        .then((result) => {
          this.ngZone.run(() => {
            this.router.navigate(['/']);
          });
          this.SetUserData(result.user);
        }).catch((error) => {
          window.alert(error.message);
        });
  }

  // Sign up with email/password
  SignUp(email: string, password: string, firstName: string, lastName: string, street: string, streetNumber: string,
         postalCode: string, city: string, province: string, country: string, phone: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
        .then((result) => {
          // Send verification mail
          this.SendVerificationMail();
          // Save user details in Firestore
          this.SetUserData(result.user, firstName, lastName, street, streetNumber, postalCode, city, province, country, phone);
        }).catch((error) => {
          window.alert(error.message);
        });
  }

  // Send verification email
  SendVerificationMail() {
    return this.afAuth.currentUser
        .then((user) => {
          return user?.sendEmailVerification();
        })
        .then(() => {
          window.alert('Email di verifica inviata. Controlla la tua casella di posta.');
        })
        .catch((error) => {
          window.alert(error.message);
        });
  }

  // Reset Forgotten password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
        .then(() => {
          window.alert('Password reset email sent, check your inbox.');
        }).catch((error) => {
          window.alert(error);
        });
  }

  // Returns true when user is logged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
        .then((result) => {
          this.ngZone.run(() => {
            this.router.navigate(['/']);
          });
          this.SetUserData(result.user);
        }).catch((error) => {
          window.alert(error);
        });
  }

  // Set user data in Firestore database
    SetUserData(user, firstName?: string, lastName?: string, street?: string, streetNumber?: string,
                postalCode?: string, city?: string, province?: string, country?: string, phone?: string) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

        // Recupera i dati esistenti prima di aggiornarli
        return userRef.get().toPromise().then((doc) => {
            if (doc.exists) {
                const existingData = doc.data();

                // Prepara i nuovi dati da salvare, fondendoli con quelli esistenti
                const userData: User = {
                    uid: user.uid,
                    email: user.email,
                    displayName: firstName && lastName ? `${firstName} ${lastName}` : user.displayName || existingData.displayName,
                    photoURL: user.photoURL || existingData.photoURL,
                    emailVerified: user.emailVerified,
                    phone: phone || existingData.phone,
                    allergeni: existingData.allergeni || [],
                    carrello: existingData.carrello || [],
                    scontrini: existingData.scontrini || [],
                    address: {
                        street: street || existingData.address?.street || '',
                        streetNumber: streetNumber || existingData.address?.streetNumber || '',
                        postalCode: postalCode || existingData.address?.postalCode || '',
                        city: city || existingData.address?.city || '',
                        province: province || existingData.address?.province || '',
                        country: country || existingData.address?.country || ''
                    }
                };

                // Salva i dati aggiornati in Firestore, preservando quelli esistenti
                return userRef.set(userData, { merge: true });
            } else {
                // Se non ci sono dati esistenti, salva direttamente
                const userData: User = {
                    uid: user.uid,
                    email: user.email,
                    displayName: firstName && lastName ? `${firstName} ${lastName}` : user.displayName,
                    photoURL: user.photoURL,
                    emailVerified: user.emailVerified,
                    phone: phone || null,
                    allergeni: [],
                    carrello: [],
                    scontrini: [],
                    address: {
                        street: street || '',
                        streetNumber: streetNumber || '',
                        postalCode: postalCode || '',
                        city: city || '',
                        province: province || '',
                        country: country || ''
                    }
                };

                // Salva i nuovi dati
                return userRef.set(userData, { merge: true });
            }
        });
    }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['login']);
    });
  }
}
