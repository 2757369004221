<div class="login-page">

    <div class="right-column">
        <div class="login-form">
            <h2 style="text-align: center">Registrati</h2>

            <fieldset class="custom-fieldset">
                <legend>Nome*</legend>
                <div class="chart-container">
                    <input type="text" class="custom-input" placeholder="Nome" #firstName required>
                </div>
            </fieldset>

            <fieldset class="custom-fieldset">
                <legend>Cognome*</legend>
                <div class="chart-container">
                    <input type="text" class="custom-input" placeholder="Cognome" #lastName required>
                </div>
            </fieldset>

            <fieldset class="custom-fieldset">
                <legend>Indirizzo</legend>
                <div class="chart-container">
                    <input type="text" class="custom-input" placeholder="Via" #street>
                    <input type="text" class="custom-input" placeholder="Numero Civico" #streetNumber>
                    <input type="text" class="custom-input" placeholder="CAP" #postalCode>
                    <input type="text" class="custom-input" placeholder="Città" #city>
                    <input type="text" class="custom-input" placeholder="Provincia" #province>
                    <input type="text" class="custom-input" placeholder="Paese" #country>
                </div>
            </fieldset>

            <fieldset class="custom-fieldset">
                <legend>Telefono</legend>
                <div class="chart-container">
                    <input type="tel" class="custom-input" placeholder="Telefono" #phone>
                </div>
            </fieldset>

            <fieldset class="custom-fieldset">
                <legend>Email*</legend>
                <div class="chart-container">
                    <input type="email" class="custom-input" placeholder="email@fillapp.it" #email required>
                </div>
            </fieldset>

            <fieldset class="custom-fieldset">
                <legend>Password*</legend>
                <div class="chart-container">
                    <input type="password" class="custom-input" placeholder="Password" #password required>
                </div>
            </fieldset>

            <div class="formGroup">
                <input type="button" class="btn btnPrimary" value="Registrati" (click)="register(
    email.value, password.value, firstName.value, lastName.value, street.value, streetNumber.value, postalCode.value, city.value, province.value, country.value, phone.value
  )">
            </div>
        </div>
    </div>
</div>
