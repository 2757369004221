import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as QRCode from 'qrcode';

@Component({
  selector: 'profileCard',
  template: `
    <div [class]="containerClass" (click)="toggleExpand()">
      <div class="content">
        <h4 class="name">{{nome}}</h4>
        <div class="qr-container">
          <canvas #qrCanvas></canvas>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .block {
      width: 100%;
      min-height: 15vh;
      margin: 0;
      background: #F94F4F;
      font-size: 16px;
      font-weight: bold;
      color: white;
      transition: all 0.3s ease;
    }
    .block-expanded {
      min-height: 35vh;
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 15px;
      padding-top: 30px !important;
      transition: all 0.3s ease;
    }
    .block-expanded .content {
      flex-direction: column;
      align-items: flex-start;
    }
    .name {
      font-size: 20px;
      font-weight: bold;
      margin: 0;
    }
    .qr-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;
      
    }
    .block-expanded .qr-container {
      width: 100%;
      justify-content: center;
      margin-top: 15px;
    }
    canvas {
      height: 50px !important;
      width: 50px !important;
      transition: all 0.3s ease;
      border-radius: 10px;
    }
    .block-expanded canvas {
      height: 200px !important;
      width: 200px !important;
    }
  `]
})
export class ProfileCardComponent implements OnInit {
  @Input() nome: string;
  @Input() uid: string;
  @ViewChild('qrCanvas', { static: true }) qrCanvas: ElementRef;

  isExpanded = false;
  containerClass = 'block';

  ngOnInit(): void {
    if (this.uid) {
      this.generateQrCode(this.uid);
    }
  }

  generateQrCode(uid: string) {
    const size = this.isExpanded ? 200 : 50;
    QRCode.toCanvas(this.qrCanvas.nativeElement, uid, {
      errorCorrectionLevel: 'H',
      width: size,
      height: size
    }, (err) => {
      if (err) console.error('Errore nella generazione del QR Code', err);
    });
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.containerClass = this.isExpanded ? 'block block-expanded' : 'block';
    this.generateQrCode(this.uid);
  }
}