import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 *
 *
 * card piatto nel menu
 *
 *
 */
@Component({
  selector: 'menuCard',
  styles: [`


    h4{
      margin-bottom: 0px;
    }




    #container {
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
    }

    .block {
      width: 100%;
      margin: 0px;
      border-color: grey;
      margin-bottom: 2px;
      display: inline-flex;
      background: white;
      border-radius: 0px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bolder;
      color: black;


      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      /*
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);

       */

    }

    #store-locator {
      color: black;
    }

    .ingredienti{
      font-size: 16px;
      font-weight: lighter;
      color: grey;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .costo{
      font-size: 16px;
      font-weight: lighter;
      color: black;
      margin-bottom: 10px;
      padding-bottom: 10px;

    }

    .nome{
      padding-top: 10px;
      margin-top: 10px;
    }


  `],
  template: `

    <div id="store-locator" class="block">
      <!--img class="store" [ngStyle]="" style="background-size: 10% auto;" src="{{this.link}}"-->
      <div style="">
        <img [src]="this.link" style="height: 100px; float: left; margin-top: 20px">
        <div style="float: left; max-width: 70%; overflow: hidden">
          <div class="nome" style="padding-left: 15px">{{this.nome}}</div>
          <div class="ingredienti" style="padding-left: 15px">{{this.ingredienti}}</div>
          <div class="costo" style="padding-left: 15px">{{this.prezzo}} €</div>
        </div>
      </div>
    </div>
  `
})
export class MenuCardComponent {

  @Input() nome ;
  @Input() ingredienti ;
  @Input() link ;
  @Input() prezzo ;


  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  clicco(): void
  {
    console.log('ciaociaociaociao', this.nome);
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout(): void{
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
