
<div style="width: 100%; height: 20px"></div>
<!--QRCodeScanner link="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fd5f6b64a-81c8-41e1-a36a-01847634.jpg?alt=media&token=4e30f9c6-e183-4879-8dbc-4593ced7e4ca"   [scannerEnabled]=scannerEnabled></QRCodeScanner>

<div *ngIf="!scannerEnabled" class="container-fluid" style=" margin-top: 50px; ">
  <div class="row options">
    <div class="col-xs-12 col-sm-4">
      <div class="wrap">
            <img class="blockico" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fd5f6b64a-81c8-41e1-a36a-01847634.jpg?alt=media&token=4e30f9c6-e183-4879-8dbc-4593ced7e4ca">
            <h4 style="font-size: 14px; vertical-align: 0px; padding-bottom: 0px; font-weight: bolder; margin-bottom: 0px">Fuego-bbq</h4>
            <h4 style="font-size: 12px; vertical-align: 0px; padding-bottom: 0px; font-weight: lighter;">Riccione</h4>
            <img rel="preload" class="" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2Fqrcode.png?alt=media&token=9fc9a7ae-e9b8-4108-b2f3-24845609e10d" style="margin-bottom: 15px">
        </a>
      </div>
    </div>
  </div>
</div-->

<!--zxing-scanner  #scanner (scanSuccess)="scanSuccessHandler($event)" ></zxing-scanner-->


<div style="width: 100%; float: left; padding: 2%; margin-top: 20px; padding-left: 4.5%; padding-right: 4.5%">
  <div style="width: 83%; float: left; ">
    <h4 style="font-size: 25px; vertical-align: 0px; padding-bottom: 0px; font-weight: bolder; margin-bottom: 2px; text-align: left;" >Scansiona il codice sul tavolo per ordinare.</h4>
    <h4 style="font-size: 17px; vertical-align: 0px; padding-bottom: 0px; font-weight: lighter; text-align: left; margin-bottom: 5px">Se non riesci ad inquadrare il codice cerca direttamente il risorante qua sotto!</h4>
  </div>
  <div style="width: 8%; float: left; " >
    <button  (click)="clicco()" *ngIf="!scannerEnabled" class="buttonqr" style="margin-top: 25px">
      <img
        style="width: 70%"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC7ElEQVR4nO2bX47TMBDGs30ByjnKZVjgAk3PsH9ACCSkPHID1F0tL6vlErzuLSgcgeUGP2TV2XWT1HHGdtO6/iS/uIln5suMO/bYRdEA8BJ4B1wAHy3tA/CqGBlKB62LTddz4C0wtQ10ogf6hztWO7W2W+/fA/R9AC6Vrc1BJsCPAQMdKgE17jZIYP3lNwwDlsBXS/sCzEa1fq37TOti03WpbTJxYca86fbXwLMiMSibgJtGOEwLPTnU+JWi8Q0SzJA5VZ3vjY5vReLQ4fAUBkBldFRF4mjZSyaA7AE4hIDOFcqejMts6tmJo1vWY7feCS1XHALAguEoHQlYbHsntFwfAiqBIk6Tqk2H0HJDEXBvybruIxLgLbeLgM9GxyeJkpLnhAR4y23Zy3o5udJtdgQEONmbLAFikAkgewA5BB7x05KJqd+2xfO2rM72jrfcGHOAKypBVhc1EfIhoBQoMhcYMw8tNxQBEzVwz96b2eYdC5u+rK7rHW+5ewOObOndQiaA7AGVawj0lLz2oiwX1QMcKj6jV6XGJoBil6CdxfW6YYcbO2drlpLXaAQshrphz1cU/Q2OSUAZmABRtjZ2CMyHVIctbizO1hokem3HHyQIuB2/N7AVRoQHIsIvhmLCVhgRHIjY38XQNuQUmbxGqPZ+mUzc6rCtLhBN7iBErg7bCIgmdxBSqQ6LQSLV4VAEVL7PHWVtEP/CyMETsKAfSRNQeRZTwhPAetfmj15kRD0gISyMxDgg8WQvOzwiE3qCDHVEphIIkFaHfQnwrg63dGCHx+QCECCSG4qAUqCIrdLrtGkRujrsQ8AkQHW4HsN50yJ0dVhMQCrIBJA9oGqGwKXRsSwSB3Bl2HuuOt4YHavEL00911lgjdeqc6qvkNW4SZEEbfx3w86/wIv6RzMM0HnyMqG7w1eNL69w1rw3rK6TDsWhXp297bo/fKJvjD8kTIBy+7OW8cXmgGpOUBOjunKeyt1hZcvpY8wb+A96SmrlZD0kzgAAAABJRU5ErkJggg==">
    </button>
    <button (click)="clicco2()" *ngIf="scannerEnabled" class="buttonqr">
      <img
        style="width: 70%"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA/klEQVR4nO3VwSoFURjA8bESWdhcFvICFmLjBSgbO0/hCSwsrSy8APEIFrKjlCjJig1l5wUklMJPJ+fWJDONO+bMwv2v5/TrnL6vybJ+/z6M4QJHyR4DHVyJtYKGWkFDraChpgfpWnGPuMUZdrGCmabRsu6wjvFe4Ev1e8YGhlPD3W4wm+Kpi+ZhvvZE99gL5v7i5iOYCJOMZazhAA8l+D1Ga+El3w9iCft4/wHfrgQX4RXPTeP0G/yGqcZ/EhjAKj5y+E5lOHfzcxz+6uDX2YDnd3woSxX2cvhiSngSTxHeTAZHfCvCx6nhhe5Op4Y7EX5NCkc87PZJcrhf1lafX0picu/cl6EAAAAASUVORK5CYII=">
    </button>

  </div>
</div>


<div class="container2" (scroll)="onWindowScroll($event);">



  <!--div class="sb-example-1 " [ngClass]="categoriesEnabled == false ? 'shadow' : ''" [style.border-radius]="this.categoriesEnabled ?  '25px 25px 0px 0px' : ''"-->
    <div class="sb-example-1 " style ="border-radius:25px 25px 0px 0px">
    <div class="search">
      <button type="submit" class="search-button" style="margin: 1%;">
        <img
          style="width: 90%"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEmklEQVR4nO1bW2tcVRReQcVUEbTaalVU9Ad4eWu8ID5YJL2okBcxrzFpOZnZ37dPMrSV44N/QmKkWp+kqKiVKooUQavG+1tbRdRWRdqKYm2NVFnMBCa7+8zFzD57cjofbAjJzFlrr7P2t247IgMMEATGmDUArtGlP0uZMT09fS3JcQDPkVwA8DvJf5tX43efkJw3xjxOcr2scgxZa7eR3E/yH3fD7RaARQBvkNyiz5LVBJKjAL7udtMtjPElyYek35EkyToAL/dq4561TzlD+hEA7iH5Y4u3+BfJAwB26tEwxtxerVZv1WWtvQPAwwB2AXir8dm85/wAYET6Cagr71UawGFjzBOVSuXKTp+nnwUwRfJIjiFOqxGljza/6FHyBMmJsbGxi/7vs/W7JCcBnMwhya0S2+3hefMA3qtWqxt6JccYcwOAgzmesFFiER79Z37vxMTEJb2Wp94A4NkcTiieGOFhewB7AsfsIZLPe4z+khQd5+lx+yzLLg4tW73LdxwAbJKCMETyK5fwennmO+SEZcQI4PNCMkYAWz0uOBFc8Pl6THm8YHMRgt90hB5dSahbISkednR5LXhVh/Nj/qREAsntbm6g0SmkwHE3ve0mw+s1jDFrSZ5p1sla+1gwgSTnnbd/IJiwznV6x9FpLqSwBccDdgYT1rlOux2dPg4mDE4npx8KEmvtI44BfgsiyBizxg07WtJKZAC409Ury7Lhngsiud4VpLW8RIa19jZXryCRgKvIAEEaqmZ1HYHLLlgSJHkqmDDW+/bNjLtLIgPAk44BFopMhN6WyADwrqPTM8GEmfrEZlkqXKvVrgomsL0+awGcdQwwXmgxBGBKIgHADrcYqtVqVwcVyvqoq9niR2KUw9p9IvmNo8v+4IJJbvHE3cnYb7+xRouQPdSY1TW73kltU0lBmJmZuVHDXZSWmEIHlZ521MEQ7XAXKoPk+478c9baB6VIkNznMcILBbTF93pcP1zoaxOCvitqMNIgvXmP0b9N0/QKiQEAIzqe8h2HXnJC48y7br+0XkmS5FKJBWvtNt9wVIlRc4SVDEv0uw22X0Z4HlkfpGl6vcQC6rOC0znKHdXubTcZY+N47fDE+VbruLX2bokFa+1Gkt+3UPBMo4G5Wyu4NE3vWrogoT+TfFQLG83tPentsjMP4NWcv52N2aYXTUN1UNnFW+tmnVO2V8LTM0/ywxafnYvKCwA2kfyiV5vXJMeN83rm1e1bfOdQkclZXsa4meTrOTdI2m16sVF3jOblFnrm2xyXn/QSh8RGkiTrdGKjrql9e21dexQ+BeBTdXMtaTut6nxDUg8vbJd+Q5Zlw0tXZUlevpJn+W6OeNZ8VF6IUB/4vEE97GYpI6rV6gYAxzrwhF9JPiBlBICRVqToEOyslBHwN0ry1oulvKpPcq4LI3xWqVRukTIhqWeKH3VhhPLxwky9hP65i8TrTykbSN5H8u8OjfCHlBEAkg494JCUFQD2dGCEp6SsyLJs2B3suiQY9HpdP2B2dvYmAL94Nn8iTdN75UIAyfu1M9X0L3pPW2uvi63XAAMMMID0K/4DOtkngDhIJ4gAAAAASUVORK5CYII=">
      </button>
      <input type="text" class="searchTerm" placeholder="Cerca" (click)="cliccoCategories()">


    </div>
  </div>


  <div class="container shadow" style="border-radius: 0px  0px 25px 25px ; background-color: white" >
      <p class="all-text"  style="margin-bottom: 3px">Mostra tutte le categorie</p>
    <div class="categories-content" style="padding-left: 3px; padding-right: 4px">

      <ul class="hs"  style="padding-bottom: 15px">
        <categoriesCard *ngFor="let cathegory of links" [link]="cathegory"></categoriesCard>

      </ul>
    </div>


  </div>
  <div class="container" style="" *ngFor="let ristorante of ristoranti" style="margin-top: 20px; padding-bottom: 20px">

    <!--div class="row" style="padding-top: 0px; margin-bottom: 20px">
      <standardCard (click)="this.router.navigate([ 'Lago-Valletta' + '/menu']);" class="col col-md"  nome="Ristorante&Pizzeria La Valletta" [descrizione]="ristorante.descrizione"  link="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/sofia%2Flavalletta%2Fc9526300-9fab-43d1-8412-bd007c2e0c75.JPG?alt=media&token=c6212f98-49f0-4887-a6ef-29f0811fd837"></standardCard>
    </div-->

    <div class="row" >
      <standardCard (click)="this.router.navigate([ristorante.nome + '/menu']);" class="col col-md" [nome]="ristorante.nome" [descrizione]="ristorante.descrizione" [link]="ristorante.link"></standardCard>
    </div>

  </div>

</div>




