import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import {AngularFireAuth} from "@angular/fire/auth";
import {map, switchMap, tap} from "rxjs/operators";
import firebase from "firebase";
import {AuthService} from "../auth.service";
import {DataServiceGenerico} from "../data-service/data.service";
import {firestore} from "firebase-admin";
import DocumentData = firestore.DocumentData;
import {BehaviorSubject} from "rxjs";


interface ChatMessage {
    id: string;
    text: string;
    sender: string;
    timestamp: firebase.firestore.Timestamp;
    seenBy: string[];
}

interface Chat {
    id: string;
    participants: string[];
    lastMessage?: string;
    lastMessageTimestamp?: firebase.firestore.Timestamp;
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  readonly ordersString = 'orders';

  constructor(
    private firestore: AngularFirestore,
    public authService: AuthService,
    public dataService: DataServiceGenerico
  ){
  }


  updateDati(nome: string, dati: {}) {
    console.log("dati common service: ", dati)
    this.firestore.collection<any>('ristoranti').doc(nome).set(dati, {merge: true})
    //console.log("dati anno: ", dati[anno])
  }

  getRistorante()
  {
    return this.firestore.collection<any>('ristoranti').snapshotChanges().pipe(
      map (aziende => {
        console.log("azienda: ", aziende[0].payload.doc.data())
        return aziende
          .filter(u => u.payload.doc.data().admin == firebase.auth().currentUser.uid)
          .map(documentChangeAction => {
            const aziende2 = documentChangeAction.payload.doc.data();
            return aziende2;
          })
      })
    )
  }

  getMenu(nomeRistorante: any)
  {
    return this.firestore.collection<any>('menu_test', (ref) => {
      let query: firebase.firestore.Query<DocumentData> = ref
      query = query.where(firebase.firestore.FieldPath.documentId(), '==', nomeRistorante)
      return query
    }).snapshotChanges().pipe(
      map (aziende => {
        console.log("azienda: ", aziende[0]?.payload.doc.data())
        return aziende
          .map(documentChangeAction => {
            const aziende2 = documentChangeAction.payload.doc.data();
            return aziende2;
          })
      })
    )
  }

  getCamerieri(idRistorante: String)
  {
    return this.firestore.collection<any>('users').snapshotChanges().pipe(
      map (aziende => {
        console.log("azienda: ", aziende[0].payload.doc.data())
        return aziende
          .filter(u => ((u.payload.doc.data().cameriere == true) && (u.payload.doc.data().idRistorante == idRistorante)))
          .map(documentChangeAction => {
            const aziende2 = documentChangeAction.payload.doc.data();
            return aziende2;
          })
      })
    )
  }


  getOrdini(ristorante?: any, fonte?: any, stato?: any, dataPrima?: string, dataDopo?: string)
  {

      console.log("ristorante: ", ristorante)

      console.log("fonte: ", fonte)
    console.log("stato: ", stato)

    let current = new Date();
    let timestamp = current.getTime();
    let dataPrima2 = timestamp.toString();

    let dte = new Date();
    let timestamp2 = dte.getTime();
    let dataDopo2 = timestamp2.toString();

    if(dataPrima !== undefined)
    {
      dataPrima2 = dataPrima;
      dataDopo2 = dataDopo;
    }else {
      console.log("che ore sono? ", current.getHours())
      if (current.getHours() > 8) {
        dte.setDate(dte.getDate());
        dte.setHours(8);
        dte.setMinutes(0);
        dte.setMilliseconds(0);
        timestamp2 = dte.getTime();
        dataDopo2 = timestamp2.toString();

      } else {
        dte.setDate(dte.getDate() - 1);
        dte.setHours(8);
        dte.setMinutes(0);
        dte.setMilliseconds(0);
        timestamp2 = dte.getTime();
        dataDopo2 = timestamp2.toString();
      }
    }

    console.log("azienda 2")
    return this.firestore.collection<any>('ordini', (ref) => {
        let query: firebase.firestore.Query<DocumentData> = ref
        query = query.where('ristorante', '==', ristorante).where('data', '<', dataPrima2).where('data', '>', dataDopo2)

        console.log("dataDopo: ", dataDopo2)
        if (fonte !== undefined) {
          if (fonte == 3) {
          } else {
            query = query.where('fonte', '==', fonte)
          }
        }

        if (stato !== undefined) {
          if (stato == 2) {
          } else {
            query = query.where('statoPagato', '==', stato)
          }
        }
        query = query.orderBy('data', 'desc')

        return query;
      }
    ).snapshotChanges().pipe(
      map (aziende => {
        console.log("azienda 2: ", aziende)
        if(aziende.length > 0)
        {
          console.log("azienda 2: ", aziende[0].payload.doc.data())

        }
        return aziende
          .map(documentChangeAction => {
            const aziende2 = documentChangeAction.payload.doc.data();
            return aziende2;
          })
      })
    )
  }


  getOrdiniCucina(ristorante?: any, fonte?: any, stato?: any, dataPrima?: string, dataDopo?: string)
  {

    console.log("fonte: ", fonte)
    console.log("stato: ", stato)


    let current = new Date();
    let timestamp = current.getTime();
    let dataPrima2 = timestamp.toString();


    let dte = new Date();
    let timestamp2 = dte.getTime();
    let dataDopo2 = timestamp2.toString();


    if(dataPrima !== undefined)
    {
      dataPrima2 = dataPrima;
      dataDopo2 = dataDopo;
    }else {
      console.log("che ore sono? ", current.getHours())
      if (current.getHours() > 8) {
        dte.setDate(dte.getDate());
        dte.setHours(8);
        dte.setMinutes(0);
        dte.setMilliseconds(0);
        timestamp2 = dte.getTime();
        dataDopo2 = timestamp2.toString();

      } else {
        dte.setDate(dte.getDate() - 1);
        dte.setHours(8);
        dte.setMinutes(0);
        dte.setMilliseconds(0);
        timestamp2 = dte.getTime();
        dataDopo2 = timestamp2.toString();
      }
    }

    console.log("azienda 2")
    return this.firestore.collection<any>('ordini', (ref) => {
        let query: firebase.firestore.Query<DocumentData> = ref
        query = query.where('ristorante', '==', ristorante).where('data', '<', dataPrima2).where('data', '>', dataDopo2)

        console.log("dataDopo: ", dataDopo)
        if (fonte !== undefined) {
          if (fonte == 3) {
          } else {
            query = query.where('fonte', '==', fonte)
          }
        }

        if (stato !== undefined) {
          if (stato == 2) {
          } else {
            query = query.where('statoPagato', '==', stato)
          }
        }
        query = query.orderBy('data', 'asc')

        return query;
      }
    ).snapshotChanges().pipe(
      map (aziende => {
        console.log("azienda 2: ", aziende)
        if(aziende.length > 0)
        {
          console.log("azienda 2: ", aziende[0].payload.doc.data())

        }
        return aziende
          .map(documentChangeAction => {
            const aziende2 = documentChangeAction.payload.doc.data();
            return aziende2;
          })
      })
    )
  }


  updateOrdine(idOrdine: any, ordine: any) {
    this.firestore.collection<any>('ordini').doc(idOrdine).set(ordine)
  }

  deleteOrdine(idOrdine)
  {
    this.firestore.collection<any>('ordini').doc(idOrdine).delete()
  }



  getMagazzino(ristorante?: any, dataPrima?: string, dataDopo?: string)
  {


    let current = new Date();
    let timestamp = current.getTime();
    let dataPrima2 = timestamp.toString();


    let dte = new Date();
    let timestamp2 = dte.getTime();
    let dataDopo2 = timestamp2.toString();


    if(dataPrima !== undefined)
    {
      dataPrima2 = dataPrima;
      dataDopo2 = dataDopo;
    }else {
      console.log("che ore sono? ", current.getHours())
      if (current.getHours() > 8) {
        dte.setDate(dte.getDate());
        dte.setHours(8);
        dte.setMinutes(0);
        dte.setMilliseconds(0);
        timestamp2 = dte.getTime();
        dataDopo2 = timestamp2.toString();

      } else {
        dte.setDate(dte.getDate() - 1);
        dte.setHours(8);
        dte.setMinutes(0);
        dte.setMilliseconds(0);
        timestamp2 = dte.getTime();
        dataDopo2 = timestamp2.toString();
      }
    }

    console.log("azienda 2")
    return this.firestore.collection<any>('magazzino_test', (ref) => {
    let query: firebase.firestore.Query<DocumentData> = ref
    query = query.where(firebase.firestore.FieldPath.documentId(), '==', ristorante)
    return query
    }).snapshotChanges().pipe(
      map (aziende => {
        console.log("azienda 2: ", aziende)
        if(aziende.length > 0)
        {
          console.log("azienda 2: ", aziende[0].payload.doc.data())
        }
        return aziende
          .map(documentChangeAction => {
            const aziende2 = documentChangeAction.payload.doc.data();
            return aziende2;
          })
      })
    )
  }



  getChat()
  {
      return this.firestore.collection<ChatMessage>('messages', ref => ref.orderBy('timestamp'))
          .valueChanges({ idField: 'id' })
          .pipe(
              map(messages => messages.map(msg => ({
                  ...msg,
                  seenBy: msg.seenBy || []
              })))
          );
  }

  addChat(chat: any)
  {
      chat['timestamp'] = firebase.firestore.Timestamp.now();

      this.firestore.collection('messages').add(chat);
  }


    private selectedChatSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);


    getChat21(user: any) {
        console.log("user: ", user)
        return this.firestore.collection<Chat>('chats', ref =>
            ref.where('participants', 'array-contains', user)
        ).snapshotChanges().pipe(
            map(actions => actions.map(a => {
                const data = a.payload.doc.data() as Chat;
                const id = a.payload.doc.id;
                return { id, ...data };
            }))
        );
    }

    getChat2() {
        return this.selectedChatSubject.pipe(
            tap(chatId => console.log('Chat ID corrente:', chatId)),
            switchMap(chatId =>
                chatId ? this.firestore.collection<ChatMessage>(`chats/${chatId}/messages`, ref => ref.orderBy('timestamp'))
                    .valueChanges({ idField: 'id' }) : []
            ),
            tap(messages => console.log('Messaggi recuperati:', messages.length))
        );
    }

    subjecta(chatId: string) {
        console.log('Aggiornamento chat selezionata:', chatId);
        this.selectedChatSubject.next(chatId);
    }

    createChat(idchat: any)
    {
        return this.firestore.collection('chats').add(idchat)
    }

    sendChat(chatId: any, message: any)
    {
        return this.firestore.collection(`chats/${chatId}/messages`).add(message)
    }

    cose(chatId: any, newMessage: any, message: any)
    {
        this.firestore.doc(`chats/${chatId}`).update({
            lastMessage: newMessage,
            lastMessageTimestamp: message.timestamp
        });
    }


}
