import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'tab-card',
  styles: [`



  `],
  template: `


    <div style="background-color: white; border: 1px solid rgba(181,181,181, 0.7); border-radius: 25px; width: calc((100% - (1.5% * 5))/4); aspect-ratio: 1 / 1; float: left; margin-left: 1.5%; margin-top: 1.5%" class="shadow menuItem" >
      <div style=" float: left; display: flex; align-items: initial; justify-content: right; margin-top: 10px; clear: left; width: 100%; height: 20%">
        
        <div style="font-weight: 600; margin-right: 10px !important; margin-left: auto !important; max-height: 1000px !important; padding-bottom: 10px !important; padding-left: 5px !important;display: grid; place-items: center;">
          <!--span style="font-weight: bold" [ngStyle]="{'color': this.statoPagato == 0 ? 'green': 'red'}">{{this.statoPagato == 0? 'Pagato': 'Non Pagato'}}</span-->
          <div *ngIf="statoPagato == 0 && bloccato !== true"
               style="padding-right: 5px; padding-left: 5px; height: 32px; border-radius: 8px; color:rgba(14,184,14,0.7); background-color: rgba(0,255,0, 0.3); display: grid;place-items: center; font-size: 11px">
            Pagato
          </div>
          <div *ngIf="statoPagato == 1"
               style="padding-right: 5px; padding-left: 5px; height: 32px; border-radius: 8px; color:rgba(255,0,0, 0.7); background-color: rgba(255,0,0, 0.5); display: grid;place-items: center; font-size: 11px">
            Non Pagato
          </div>

          <div *ngIf="statoPagato == 3"
               style="padding-right: 5px; padding-left: 5px; height: 32px; border-radius: 8px; color:rgba(0,119,255,0.7); background-color: rgba(0,119,255,0.32); display: grid;place-items: center; font-size: 11px">
            Completato
          </div>
          <div *ngIf="bloccato == true && statoPagato !== 3"
               style="padding-right: 5px; padding-left: 5px; height: 32px; border-radius: 8px; color:rgba(150,49,190,0.7); background-color: rgba(159,95,213,0.5); display: grid;place-items: center; font-size: 11px">
            In Preparazione
          </div>


        </div>

      </div>
      <div style="display: flex; width: 100%; align-items: center; justify-content: center; height: 60%">
        <p style=" font-size: 25px; color: gray; font-weight: bold">#{{this.numero}}</p>
      </div>
      <div style="display: flex; width: 100%; align-items: center; justify-content: flex-start; height: 20%">
        <p style="margin-left: 10%; font-size: 12px; ">Totale: <b>{{this.totale}}€</b></p>
      </div>
    </div>

  `
})
export class tabCardComponent {
  @Input() stato ;
  @Input() statoPagato ;
  @Input() totale ;
  @Input() fonte ;
  @Input() nome ;
  @Input() numero ;
  @Input() bloccato ;


  constructor(
  ) {}

}
