import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {Scontrini} from './pages/scontrini/scontrini';
import {Account} from './pages/account/account';
import {Categorie} from './pages/categorie/categorie';
import {Menu} from './pages/menu/menu';
import {DettaglioMenu} from './pages/dettaglio-menu/dettaglio-menu';
import {SignIn} from './pages/sign-in/sign-in';
import {AuthGuard} from './shared/guard/auth.guard';
import {InsertMenuComponent} from './pages/modifica-menu/modifica-menu';
import {PreferenzeAlimentari} from './pages/preferenze-alimentari/preferenze-alimentari';
import {PaymentsTestPageComponent} from './pages/payments-test-page/payments-test-page';
import {ScansionaTavolo} from './pages/scansiona-tavolo/scansiona-tavolo';
import {Home} from './pages/home/home';
import {Scontrino} from './pages/scontrino/scontrino';
import {Carrello} from "./pages/carrello/carrello";
import {MetodoPagamento} from "./pages/metodo-pagamento/metodoPagamento";
import {Recensione} from "./pages/recensione/recensione";
import {SignUp} from "./pages/sign-up/sign-up";
import {Tavoli} from "./pages/tavoli/tavoli";


const routes: Routes = [

  { path: 'login', component: SignIn },
  { path: 'sign-in', component: SignIn},
  { path: 'registrazione', component: SignUp},

  //{ path: 'scansiona', component: Tavoli },
  { path: 'scansiona', component: ScansionaTavolo },



  //{ path: 'tavoli', component: SchermoCucinaComponent, canActivate: [AuthGuard] },
  { path: 'scontrini', component: Scontrini, canActivate: [AuthGuard] },
  { path: 'recensione/:idScontrino', component: Recensione, canActivate: [AuthGuard] },
  { path: 'scontrini/dettaglioScontrino', component: Scontrino, canActivate: [AuthGuard] },
  { path: 'account', component: Account, canActivate: [AuthGuard] },
  { path: 'tavoli/categorie', component: Categorie, canActivate: [AuthGuard] },

  { path: 'carrello', component: Carrello, canActivate: [AuthGuard] },
  { path: 'pagamento', component: MetodoPagamento, canActivate: [AuthGuard] },



  { path: ':ristorante/menu', component: Menu},
  { path: ':ristorante/menu/:piatto', component: DettaglioMenu},
  { path: ':ristorante/menu/modifica/modifica', component: InsertMenuComponent },


  { path: ':ristorante/:tavolo/menu', component: Menu },
  { path: ':ristorante/:tavolo/menu/:piatto', component: DettaglioMenu},
  { path: ':ristorante/:tavolo/menu/modifica/modifica', component: InsertMenuComponent },

  { path: 'menu', pathMatch: 'prefix', redirectTo: '/' },


  { path: 'account/preferenze-alimentari', component: PreferenzeAlimentari, canActivate: [AuthGuard] },
  { path: 'paymentTest', component: PaymentsTestPageComponent, canActivate: [AuthGuard] },


  //{ path: '', pathMatch: 'full', redirectTo: '/' },
  { path: '', pathMatch: 'full', component: Home, canActivate: [AuthGuard] },

  { path: '**', redirectTo: '/' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
