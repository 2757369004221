import { Component, OnInit } from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {AuthService} from '../../shared/services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dettaglio',
  templateUrl: './account.html',
  styleUrls: ['./account.css']
})
export class Account implements OnInit {

  constructor(
    public authService: AuthService,
    public router: Router
  ) { }

  items = [
    'Item 0',
    'Item 1',
    'Item 2',
    'Item 3',
    'Item 4',
    'Item 5',
    'Item 6',
    'Item 7',
  ];

  scontrini = [


    {
      link: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADxElEQVR4nO2ZW4hWVRTH16SVF1IzIkTqoTI1GwwUFAUfBMnwLfhKFH0wGVA78539/5/D+fTlJEI+jToPXiIIH8Xu9FKa4AXEHoTyglj2IGMRQ04zoaKjqSxaBz/E8Xw3Z59wfrDf9lpnrb322XuttUVGGOH/hXPuVQArAWwG0A1gG4BNJBenaTpaikypVBoFYA3JMyTvDDUAXCC5SIpIGIZTAByvMvZ3kvsBbMc9ukmetznXAOwEsECKQrlcnpQZCOAXkstEpO1Bc9M0fYJk131R2pem6TjxDYC95sTJJEkm5s03Z16Poigk2WuyX4pPkiR5ieQtADedc7PrlQ/D8GUAfeqMc26p+ILkelvRL5rQ8WG2xcQXJL8yI95vVEe5XJ5pOn4TXwA4YdtifjPHNsnrJG97++lJ/mpba0aTegZUj56ArbOuPgPOqQFxHE9vRg+Aq6onCIIJrbOuPgOO2P5u+KZ2zk22qF4RX+hJY44sb0LHPNNxSnxhiaAasbUJHZFFZHdrravPiLfMiEON6iD5reqIomhFa62rz4i3zZF/GknP0zR9CkC/RXWZ+ILkYTMiGSpRzKGN5EbT8YP4Ijt+G8mzMlTWonpWfAHgMzNiTRM61lpE9osvnHOrzZGLWjnVK28F10VzZJX4wvKkzzVP0jRDa41aZa0uGciy51KpNEp8Q/InNSgMw1m1yjjn2i0S56Qo4L96XFf2o1pl9BI1R/ZIUSA5xxzpC4Lg+Rrzq8t2ES6UIgHga1vhw5VK5bmh5sVx/AyA783xg1I0nHNTSf6ZV2hp7WJO9EdR9IoUEQAn8xzRDkrWqJOiAuB0niNZjQ6gR4oKrfQl+UZOC0nn9EoR6ezsfEGrPFvtF4eap008vUAB3FCnpEg459oB/Gwr/V3efM2rssswDMO54pswDGdpZUdyMOv9akM7T06P56quvXYqP42i6E0ZTrSAIvmOVoWWY6kD/2oPuJ52jt4nJD9R2aqG9jGS73Z0dDz5KB0YQ/KDqmz1jrVxPn7Yz52HnWK7sv/LxiUAZefc2Fb60Kb1hr15ZB/SbdFZqVSebdVHkiSZCGBD1b+m4w+tVxqsPu9BcjzJb6oicGI4uuYAltz3cKTpz/iGlAVB8DSAo6boLwDvyTBDclXVkX5UmxWNKNmR3cAAXhO/R3uPRaerLuE4jqfp442dKPPEM865+WbLoL4Y1ywIYIutwAEpCAAO2g7ZXLMQyR9NaJ0UBADrsgOnZiGSfz/srdznANBXkxN6Cfk2ljlDL+cmgjvCCI8ddwFm3GIgpPSY7wAAAABJRU5ErkJggg==',
      nome: 'Account',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEp0lEQVR4nO1ZWYgdVRC9MWoMEVGJ+woKLkQUNfohERTRfLjk5wkuGIIy6piefvec2+/Nh9gocUP8EjTijxHRgCFxQYNiEDQJwogouCaa+GPiHuOCJsZEaqau9nS639L9YrphDjQMd6mq827dqro1xkxhCl0Rx/EBJIcBbAKwluQsU0cAuJfknsR3t6kbAFxNcjeAvwA8JEQAfGjqBACzAWxV410QBDMA7CC5q1buRXKFknhL7omOva/uNdfUASStkvg5DMNT/TiAF3R8gak6SF5KcqfejUkGk3xUidxpqowois4nuU3d58H0PCcgc/eZqoLk5Z6EuFCj0ZiesWaREnnKVA1iMIBRjUhC4vmhoaGDstY6567TNS+aKsFaexnJMTXub3WZaXnroyiapyfyhtnfCILgMHWRdYlsvVEuebe9JOfq+nf+F2PFNUZHR4+Q0EnyYmvtLQAeEOMlSycIbJFQG8fxwb3Itdaeq/ve67tEALAGwC+peqfwB+B3AC+TvD7vLuSB5Nkq4+N+Ni0pafQ2ABsArCf5LICWc+4Ka+1MUxDW2tOVyKZ+ijUx5k9IoePcsX38ALvlK2psF9mnKJGtPW0Qd9INKKBsnxFpNpvH+dKlV2O2y4aRkZFjqkQEwOzEXZPSfgPJdu5dk5guXxFl+5JIMBG6swLIa3EcH1gbInEcH+JPQ/7WTP+djgW1IdJoNKbrKfwrH8ANOjZWGyLxRCNiEhEfAORkakMkCIIZavQuP2atna+utXavDQC+rWLUGh4ePlSN3iFljSRYkptlzFp7e5Yxq5U5q0QkDMPDc6qIVZlVsz5yfGZ34ocVca2jknmE5OcSrbIeYUmD2iVrre0AvgDwLsnlIs85d2WZWqvZb2ZPPWQ+HVTlm6h+V0pjwbd5ekW73T65r1orCZJztKvRsZ8E4A9ZI4lKDJT3iCq+AMBNJO+XB1HqPSIPqoWdXoVJOOdO032bTREAeEYFPNlhzU+yRgh0u7AAbiP5QeKU1odheFY3O6IoOkP3fFKIiPi2Cvgmzx0AfC1roig6vle54l5yj1T2r865G3vwjj3ScSxTGmzReH1RjlHjBsnxF6iflvqM3SnkN5vNC3XduiI8vKFPqBu0suZJfqRK5hSRT3JIG9SiI8yx4SqdX2OKQo5dDX0lx5Cxsg1ma+3NvjwieU16XoOGEFlZVIcIOUkN/THrngB4W+/IvMJKJuev79P3TU5K55aW0SGCvtJ7ck7G3Os6N7+UEmOmkXxVDX4z+aNJA28gvV/phqigO9JzAJ7WuVtLKZnQc7RESHWjuxLjj6uOxWUVjOQ1kUneo3NLzAAAYIEvdbyLSc9XyTVKCXfOXZIXxxPBYLUZEEiuUpkvqcttLBMZk4JnaYjcKfE/OWetPUHLFMkFi8wA0Gq1TpTgosY/rP/8+a3XFmtH+HyRlRilpPblv3PuvNLKzLjMa/XH8fXZikHIFSLLOv37C8BjpWP93joXSpuU5JdSbw1K6GL9ZZZlzcvzWIn8YKoMAGf6aCLulX6d+Y65JDRTdfC/fNLpW26qDmvtTJKPAPgs9VAaL/VJPmetPXJ/2zkFUxH8AxZVgdr6tnUwAAAAAElFTkSuQmCC',
      nome: 'Promozioni',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADdklEQVR4nO2ZO4jUUBSG4xsFwRcIYqXYbCEoCoqCjY3gYjVbKBYijMW6M3P//2Z30CIgYregWK3gsxAGW23UwkIFH4UKIoKKhVqtz1XwsT446417NyYzmZlMJoE9cJlMcnNyvpzcc05OHGdaMiok95P8CKDQqg6t9Wql1DaSPdVqdanTDVFKHSD5G8APkn2t6AAwKjr8AeAbyVck7wO4TPIsgGMAylrr3YODgys75ZWj7cBYEE9IvrehIsabjoC0C+Mb6P8fGBiYJ3ed5EYAO0nuA3CI5PHg3EzBcPJO9wBYFmduIgYnCQNgXcij8z1qjaQG0gyMPD4AXpu5j82YsvCjhtZ6rZMFGNd1F5J8YAy74XneXP+YbAtkpVLZYK2RwyTfWpHtZalUWt5VmEKhMIvkFWPUU6XUkhi6eo2uTyTvmHNvSWBw0hCSR8xFxwHskX0AThqjRl3XXdNIh+d5CwC8MHpKkjQBPDM6LjhpScAzF41BXwFsjXn+sDnnnnhT9skaITlm9g91HCLEMzJ+kdwb5zxjsESxcdd11wd07jCe/qm13uWk7Rk/O5O8DuCElDninWq1utieL3cfwF0zf1gpNV8ptQlAP4AzAB4KBP8eH0sNJAQmtPwAcE0ASY5Y+5+bR3PKfHufk5Z4njc7CAPgNsnzJvl9qQcpRhsviDf6xTviJaYNAqAWhDHGTbwCeJ43s1KprDKhdsgC+Gd0mF6mDWKMqln//4NpxUB2AyR4wbA8kxuQaiA6RXkm8yCcjE6nwtaMX85kHgTAZ2u7FgWTeRDHcWZIdDKVrUSnXmuODZN5kGbKmfyChFQAfbkFaRSacwUSF4ZZB6kXmtvR27Y0e0EAl4rF4pykKoBurpHI2sz2DPMAEqc2Yx5BwmCYlaKx0fyIY01XAIlJ4G3vHYCb8jprPhdsDzbcGhnYbAXQ0aIx5HVWWqhX43bem6kAEhPbMNd1V4gXTGN6xHjH71NNGUlVAIlJI8Oi3tnT+NjUlajVagWQeRDErAAyD8KYFUAuQNhEdyZTCdGXep1GdsozdhaWfCE9XpMvilrrLeVyeVE9kIgm9oRO+Q27ZkdgAKBRjzcsIQaNjugHI+q6HfOMny+sLkrsJjbJR8EmthNDGrVnE5VgQgRwjuRpkge11pvjGh0D5oOTdyFZlK/E3bZjWpwI+QNyny/j7OWjJQAAAABJRU5ErkJggg==',
      nome: 'Preferenze alimentari',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADDklEQVR4nO1YO4gTURTNrp/FDyj+f2BjoZVgpaWFHxBBkYggKlhEWMlm7zmTbMTiidUWNgt+sBJsFBsVBAuVIGq1gojFivgXRFB010KDrhu5yxt3CJk4YSeTGZgDQyZv7tw35737fZlMioSD5COStXZeAB5GQaQWxZWJikhS9f9DSiQgmO5Ii0hNKyCYmlb8TGsMwNF26Y+SyC79NcZ0G2NmJpaIMaZbROYAeAHgZT6f70msMwI46ClX9ieWCMm7HiK3k+ojawH8ITlBskpyvFQqrUkikVO2lL9P8qq9P5k0Il0kX6l+ETlMcrcl8lqfxZaIMWZuf3//SgDrRWQzgONW/xjJecaY2QC+2LEDanaFQmFhLIiQvETyM8lfTRqri648gAs+Mt8AvAXwFMCZThAZrmtrfwP4CuCD5g0A93TlXXkRWaf+oualcj6k3kdOpFwuLwZQsSS+A9jTqg4AO6z5TfqPiGzsiI9o6QFgyOqaADCoGT0TPFn+8ES2ZR13dpI511cA3BoYGFjgJ5vNZmcoYa8f5XK5WbEJvyKy1ROZzvvJuSQA/CR5JJZ5RKOO/cjBAESGQpm0HURIPrYfuc0dU7NRc3L/O46z3c49HNakYZvWIltbVTVB2jkOWXN7oxFKx/SZW3vpO3Ekss/uRkWzPMnrDfLENQBLbJSqOY6zN3ZEAJyzRJ54coM69AkROeaOkfykZ8L2/mwciTyvW/0HWnu5z+0u3ayTGYkVkXw+v9RTqoxqXvGpcrv0QELrK1fecZwVcSLSo74B4EqxWFzljpE83UheRFYDuKH9fLPkGZfGaiQ9jW8F6dlvQDA9+23jionITgDP9NL7sPVPC0Em0vZUy4r6UgPAnUKhsGG6+kNBs4l6e3vn23K76iY5TGHUvltVGZVtVX+o8JlIM28WwDtP+3q5r69veV2vru3tuJX5qJm8vsVlp4gUi8VNnmJusl9wHGeL3/v/k2fURIKusA98d5BREwlq883QyKcYNZGgUSgIGkW5TLthq9XAeaEVyFTeqYStO0UmYvwF0gP4JAXmWukAAAAASUVORK5CYII=',
      nome: 'Preferiti',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    },
    {
      link: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACjUlEQVR4nO2ZO2gVQRSGJ0FFLogiqLHQ+MJeLIJoCsHK3sbCRrmS4t7d+f9ZtrA4hYXiVUgrNkFsFMFajeAjFmIEKwUFQXyh4CtRkQsaGTILl0XdNWTd2WQ/mOqe2fv/e848dkapmpqFBYANJA8DOE/yMcmPAJ4BOKaU6lO+IiINrfV+kqMkH5Gc+VMDMKI8og/ADpIxgBskv6cEfyZ5BUAriqLhVqu1BsAR99vdUpU7MQdIniX5MvWWf5CcBHDSGLNPRJal+4dhuN7FvvivwkVkuRUF4BTJhyR/psQ/J3nOmtNar856XrPZXOr6dgsXH4bhFpJNkpcATKWEfwVw3ZYTyZ1zGbR0zypEvNZ6G8mOTXm6XAA8IHlCa733d+XihREA2wFcTJXMawBjxpiDJNfO6x+qAoxorY+S/NYzUC8bY3apKgFgpCcDF2xpqaoRBMGmnkzcJnmH5Pu/LWAFt2kAE7ZC7KyW2wiA4yWKnslo94wxA3mNTPTMSq/soI7jeKUqiSAIVtlsAPjkdE3mmh0BvHEmbMfNyhOMMVsBfHBm2pkdbE26YK08Q2t9yGm7nxmcbO7iON6oPCOKohXJDiIzOBkfIrJEeQjzLpiF7nXmAdZGPIN1RjyDdUY8g1XJCMlBkuMAvtjP5vTCXCUj46kd77WqGplOnQtMVdIIZk9hFkRGBq1492V4tbJjJIvaiG+wLi3PYJ0Rz+BizEjXHT40lGeISCP3RRDJpzbYGLNbeUYURcNu//UkM5jkGef6poj0K08QkX53qG61dTI7tNvtdSTfOee3AOwps8xEpGE1JCYAvM19wURyKDHjU8OsiaF/ehM2MwBO23pMJoCSWtdp6Njr7zkmtqZm0fMLsvzIATDdRn0AAAAASUVORK5CYII=',
      nome: 'Portafoglio',
      luogo: 'Rimini, 22 gennaio',
      prezzo: '56.00 €'
    },
    {
      link: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB6ElEQVR4nO2XPYtTURCGA6s2WlgsioWIWmijFqL+AV0Q0dXi/oUoAZN73mfSiVcERf/Fuo2F0V1FrbW3EEQQxX+gCNqsH6sy7CwEEcn9SIo1A0PIyczLk3PmzNzbak1tav+j9Xq97cAJMzu57v7d1ycGYWb7JS0DP4Bff3FfX8rzfN9YQVJKR4CPwE9gAHSB9pB3Jd333yV9SCkdGgtIURSbJL2R9BU4/a9Y4IzHSXrteY3DmNl8HMPVUeKBaxF/tnEY4HaI7xkxfq/HS7rVOIykBa+FUbfd46J2FsYB8xj4VDLnM/BwHDCvvCBL5rwFXjYK0ul0tkX/uFMS5m7kbW0MxswueDGmlC6WhLkcRXyuMRhJD4BvkmbL5OV5vst3RtK9pkAOxlYPquQDTyR97/f7B2rDAANJq2Z2uEp+Sun4+vioBWJmc3Hmi3V0/Jhcx/UqCRRrTeud95Zut7uzJsxu4IvrZVk2U1oAOBbt/0odkCG966F3tEryDmAluuj7uh46K65b6d+Y2Zykp8CLuu46kk61NrSZ2bzfsKIotgyvt9vtzT4yJJ2fGAxrj5h+5W/+sX4jivTSxGCyLJuR9DyAHgHEp4M8q3R9605ySYvenQNqNR7AmpvQZU3SrL8vlR2iU5vahrPfLgR0FaCFg2AAAAAASUVORK5CYII=',
      nome: 'Notifiche',
      luogo: 'Riggione, 8 gennaio',
      prezzo: '93.60 €'
    },
    {
      link: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAACu0lEQVR4nO1WWWsUQRDuKJ54gle8IOKxKkZQvH0IRA1e4LXeSkDch4Xdmfq+nt2NSEZfBBX0SREPEH3SByEq8Vbwl0nhjDSTiTu7m7zNB8Xu1ExXV1fXV1XG5MiRI4cxJHsAXADQIHkfwEMAt0leI7m9WCxOndQ4BUGwnOQwye8AHonIZWvtPs/zNgJYLyK7SZ4CcA/AKMknAAoT6kSpVJpGcojkCMk+Y0xXlnUisoXkCwBPG43Gwo4d8TxvAYCPJM+3a8Nau4fkz46iFIbhTABfgiDYZjqE7/vdAL4BWNWWAQAPSB51dfoMoJgiJ6I1A7GO5CZ3bRAE6zTKWa/5H6y1ywC8Seorlco8vf+k1Ov1+dGGc2NdpVKZkVxP8iaAO2EYzm7FGZ/kYcdIj1I2liAINih9XV2aWGt7XbvVanWpHpLkFc2lTM4oLUVkhRORxb7vr3GkW8Od0I2Rer2+OsX2V/0FsD9i5/8B4JVLRwCLmm0cS7lcnhNHM9YpGRxnPjj/z6Q5nPR+WER2xs++72+21h7IIhpRvUIA/bFOD+PUrJHYbhiGU0iWmkVmL8m7ZoJB8izJcmKvS00XAhjVhEvojo1D7TGSpLayS+uWMtLVW2svZnGmQPKTiMwynaOL5GOSx5MvMjmjsNYeAvA+Yk9b0DwB8Nxaez3tPYDBzMYAFAD8EpG1rTqih1EquzUrYXtA2daSUQA3SO5o9p2ySES2khTtRVpx4+qc4miviBxpyREFgJckl5hERyf5jORbAL+17wB4F80z/WEYTjfjQHucRs20iuLfsv/Z1VlrTwL4EdcizSnNCRE5nWV2aXskIdkXjZS7ANSi8A+lsUwboJ5ap0DdMKL5YLLKZmZQErVabSXJ1yRvATjYznwbRfJc3Ba0DUxQyWgPmsg6xIvIVQCe24hz5MhhJgF/AIM/rlgNzvSNAAAAAElFTkSuQmCC',
      nome: 'Assistenza',
      luogo: 'Riggione, 29 gennaio',
      prezzo: '147.50 €'
    }

  ];

  immagineProfilo = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FGroup_3_cc.png?alt=media&token=0d4a5c5a-3784-4206-aa5e-28e4a669032a';

  logOutLink = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD6klEQVR4nO1aXY9dUxhejTIS3x1FLwh3hBTpDzhXZZs563nWxr4Y3InhqqbT+9IQCcIF4YcQxA1K0pa2vkZUW6RRBJHetJnzrqlkNe+etY8zk5TZZ/Y+++xJn+RcTOasddaz3u/3XcZcwgZFyLLrxNqHPfmSB97z5AkP/CmkCOCFPOPJY/n/gFfFWobp6RvMOCDMzl7ugcc98KEe1pOhzEeAf3Stt/aJkCQToyeQJBNC7vHkr/1Dkec9+ZEn9wn5iHS7d55N05v0u0pYb1+cu0tIJ8BeD3ys0hog9psA86HTuXIkJIScytXm31v91pNPhizbUnavQF7vgacEONInBPwozj1UrxSAtwcILPSsTYIxm6rYX/cS4KuB/d+qXDrn0nSbkIejCvWEnAudzuYaHMZlAjwrwGL8rcNnrb25ks0Xu93bPHmyELsH7jc1w1u73QPHo3RO9qy9Y92S8MCp4naCc5NmRAjOTQp5qCAztGSiZzoYSRzUOFH5af/vDDt3XlWQyS9yGJvx5OtRnU6FLNtqGkJwbrJQM3UApTfwwMsamX2a3lvLCcucxdrthQNQ72baDCHnCnsZWdCsA6HT2SzA19Fe5kybIctZhdrt70NJRXMmAQ4Iud80iGDMJiGPRhV7bBgSC9FrfG4axhL5dCTywbAkFvRv0zCCZtHL9c35NcU2jR0xs1X2x85NTd1ixgRC7o9Gb1sniUF48vl4wa+YNkqigABp9F7vmDZKooC39u540T+YNkqiQOh2b4yX/ZdZjRXlZk0fAT4zFSAkyUQ0dmmKyKe1EwmrVStNt5kxRciyrRdVrQ1j7G2SjCx3NC/ufjdUQGyDZKRIUZzrrmnBOEomZNmWftKYJNeueeEKMuQXpmEsAc9E+3i/9OJI5oi2hUzzhdWXORHnZkxbIc51ozRONzKGqKz5QH4T7XWXaSsEmI/SOF5KGmPVoCPv0ylA3qBz7oGyi18rWqZNut2wnFcV04A3hh3sHIhu95BOl2o56X+dIcuu1s5N0cEZ2sC1uPLkz5HM0ZGOFbI8s8hJ6Khv3VqxSN7aH7oAPy05t8OMwCZ8oU7kiR55eyUbq2Q0qg+M3vbopNbU09+dLwxbJVK5fYYsu8KTbw6Mo7/T6WsVw9A8YgPTRZwoDLvWoNcjH9SCZpDQknOz2hAYJgHU3KmfdjCqUlkXux7pCLDbA78MvmIQ4BNPviDAo/o4QFVSu+b6/XzylKb3aFEkwHOaiq94LQGc1ojdzAsIfcLh3IxmoqteMaytGaGvJfRtinMzY5M7BeCa2AF80QPvRvX7I3cOWj8Af3vgey1PtbLTvm2peuISTLtwAVmTR5dWaUqFAAAAAElFTkSuQmCC';

  nome = 'Marco Polo';

  luogo = 'Visualizza Profilo';

  prezzo = 93263269631;


  numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  disattiva = true;

  displayName: string = 'Utente';
  uid: string = 'GmxmhVQ2KZUHgmaksww8OSHk1jo2';

  ngOnInit(): void {
    this.authService.afAuth.currentUser.then(user => {
      if (user) {
        this.displayName = user.displayName || 'Utente'; // Imposta displayName
        this.uid = user.uid; // Imposta uid
      }
    });
  }

  dropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.items,
      event.previousIndex,
      event.currentIndex
    );
  }

  drop(event: CdkDragDrop<unknown>) {
    moveItemInArray(this.numbers, event.previousIndex, event.currentIndex);
  }

  /**
   * Predicate function that only allows even numbers to be
   * sorted into even indices and odd numbers at odd indices.
   */
  sortPredicate(index: number, item: CdkDrag<number>) {
    return (index + 1) % 2 === item.data % 2;
  }

  cliccoAccount(account): void
  {
    if (account.nome === 'Preferenze alimentari')
    {
      // this.router.navigate(['/account/preferenze-alimentari']);
      this.router.navigate(['/account/preferenze-alimentari'], { skipLocationChange: false});
    }
    if (account.nome === 'Preferiti')
    {
      // this.router.navigate(['/account/preferenze-alimentari']);
      this.router.navigate(['/account/preferiti'], { skipLocationChange: false});
    }
  }

  //http://localhost:4200/scontrini/dettaglioScontrino
}

