import {ChangeDetectorRef,Component, HostListener, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import firebase from "firebase";
import {CommonService} from "../../shared/services/firestore/common.service";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {UserDataService} from "../../shared/services/firestore/userData.service";

@Component({
  selector: 'app-categorie',
  templateUrl: './tavoli.html',
  styleUrls: ['./tavoli.css']
})
  export class Tavoli implements OnInit {

  public ciao = "";
  public categoriesEnabled = false;
  public scannerEnabled = false;
  public transports: Transport[] = [];
  // messaggio per il debug sotto
  public information = '';
  currentItem = 'John Doe';
  currentDescription = 'Architect & Engineer';

  currentLink = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Flobster_1f99e.png?alt=media&token=c3fbe73b-01ec-415b-8a38-fedb138ec75a';

  ristoranti: { nome: string, descrizione: string, link: string }[] = [
    { nome: 'cannoleria', descrizione: 'Available', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/sofia%2Flavalletta%2Fc9526300-9fab-43d1-8412-bd007c2e0c75.JPG?alt=media&token=c6212f98-49f0-4887-a6ef-29f0811fd837' },
    { nome: 'fastfood', descrizione: 'Ready', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/sofia%2Flavalletta%2Fc9526300-9fab-43d1-8412-bd007c2e0c75.JPG?alt=media&token=c6212f98-49f0-4887-a6ef-29f0811fd837' },
    { nome: 'pasticceria_1', descrizione: 'Started', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/sofia%2Flavalletta%2Fc9526300-9fab-43d1-8412-bd007c2e0c75.JPG?alt=media&token=c6212f98-49f0-4887-a6ef-29f0811fd837' }
  ];

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];



  constructor(private http: HttpClient,
              private changeDetection: ChangeDetectorRef,
              private router: Router,
              private commonService: CommonService, private dataServiceGenerico: DataServiceGenerico,
              private userService: UserDataService
              ) {

  }


  tavoliVuoti = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},

  ]

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e): void {

    // 350 with search bar
    // 280 without search bar
    /*if (window.pageYOffset > 280) {

    } else {
    }

     */

    if (window.innerHeight + window.scrollY + 1 >= document.body.scrollHeight) {

      console.log('ciaociaociaociao');
      //this.ristoranti.push({ nome: 'Fuego-BBQ', descrizione: 'Available', link: 'https://www.fuego-bbq.it/images/galleria/2.jpeg' });
      this.changeDetection.detectChanges();
      console.log(this.ristoranti);


      // you're at the bottom of the page
    }

  }

  scroll(id: any): void {
    console.log(`scrolling to ${id}`);
  }
  public scanSuccessHandler($event: any): void {
    // this.router.navigateByUrl('/scontrini');
    // this.scannerEnabled = false;

    this.information = $event;
    //this.ciao = this.information;
    console.log('scannerizzato: ', this.information );
    this.scannerEnabled = false;
    this.router.navigate(['/']);
  }

  ngOnInit(): void {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.retriveCarrello();
      } else {
        // No user is signed in.
      }
    });

  }

  findTavolo(tavolo)
  {
    let index =  this.camerieri.findIndex(c => c.tavolo == tavolo)
    if(index > -1)
    {
      return this.camerieri[index]
    }
    else
    {
      return null
    }
  }

  numeroElementi = 0
  tot = 0

  calcolaTotaleOrdine(ordine: any) {

    //console.log("receive calcola index: ", this.getIndex())

    //console.log("receive calcola totale: ", this.camerieri)
    //console.log("receive calcola totale: ", ordine)

    this.numeroElementi = 0;
    this.tot = 0;

    if (this.camerieri.length > 0) {
      if(ordine !== null) {
        if (this.camerieri[this.getIndexOrdine(ordine)].hasOwnProperty('carrello')) {
          this.getProperties(this.camerieri[this.getIndexOrdine(ordine)]?.carrello).forEach(piatto => {
            //console.log('piatto', piatto);
            this.numeroElementi = this.numeroElementi + this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].quantita;

            let prezzoPiatto = this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].prezzo;
            this.tot = this.tot + (prezzoPiatto * this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].quantita);
            //console.log("tot nuovo: ", this.tot)
          });
        }
      }

    }
    return this.tot.toFixed(2)
  }

  getIndexOrdine(ordine: any): number
  {
    return this.camerieri.findIndex(cameriere => cameriere.ordine == ordine)
  }

  getProperties(object: any)
  {
    //console.log("oggetto: ", object)
    //console.log("oggetto 2: ", Object.keys(object))
    if(object !== undefined)
    {
      return Object.keys(object)
    }
  }

  camerieri = []

  retriveCarrello(): void {

    console.log("ciaociao",firebase.auth().currentUser);
    console.log(firebase.auth().currentUser.uid);

    this.commonService.getOrdini(this.dataServiceGenerico.getRistorante(), 3, 1).subscribe(
        (dataCamerieri) =>
        {
          console.log("dataCameriere: x", dataCamerieri)
          if (dataCamerieri !== undefined) {
            console.log("dataCameriere: ", dataCamerieri)

            this.camerieri = dataCamerieri
            this.dataServiceGenerico.setCamerieri(dataCamerieri)
          }
          else
          {
            console.log("dataCameriere2: ")

            this.camerieri = []
            this.dataServiceGenerico.setCamerieri(this.camerieri)



          }
        }
    )
  }


  aggiungiOrdine(tavolo?: any) {
    if (tavolo && tavolo.occupato === 1) {
      // Se il tavolo è occupato, trova l'ordine esistente e aprilo
      const ordineEsistente = this.camerieri.find(c => c.tavolo === tavolo.numero);
      if (ordineEsistente) {
        //this.cambiaSchermata(1, this.camerieri.indexOf(ordineEsistente), ordineEsistente.ordine);
        return;
      }
    }

    let current = new Date();
    let timestamp = current.getTime();
    let psw = timestamp.toString();

    let filterderArray = this.camerieri.sort((a, b) => b.ordine - a.ordine);
    let nuovoOrdine = filterderArray.length > 0 ? +filterderArray[0].ordine + 1 : 1;

    let ordine = {
      ristorante: this.dataServiceGenerico.getRistorante(),
      id: 'c1' + psw,
      displayName: tavolo ? `Tavolo ${tavolo.numero}` : `Ordine 000${nuovoOrdine}`,
      ordine: nuovoOrdine,
      fonte: 2,
      statoPagato: 1,
      data: psw,
      tavolo: tavolo ? tavolo.numero : null
    };

    this.camerieri.unshift(ordine);
    //this.cambiaSchermata(1, 0, ordine.ordine);
    this.commonService.updateOrdine('c1' + psw, ordine);
    this.dataServiceGenerico.setCamerieri(this.camerieri);

  }


  clicco(): void {
    // this.router.navigate(['/scan']);
    this.scannerEnabled = true;
  }

  clicco2(): void {
    this.scannerEnabled = false;
  }

  cliccoCategories(): void {
    this.categoriesEnabled = true;
  }

}
