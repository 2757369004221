import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-up.html',
  styleUrls: ['./sign-up.css']
})

export class SignUp implements OnInit {

  constructor(
    public authService: AuthService
  ) { }

// Funzione di registrazione che passa tutti i parametri
  register(email: string, password: string, firstName: string, lastName: string, street: string, streetNumber: string,
           postalCode: string, city: string, province: string, country: string, phone: string) {

    this.authService.SignUp(email, password, firstName, lastName, street, streetNumber, postalCode, city, province, country, phone)
        .then(() => {
          console.log('Registrazione completata con successo!');
        })
        .catch((error) => {
          console.error('Errore durante la registrazione: ', error);
        });
  }

  ngOnInit() { }

}
