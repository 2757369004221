
<div style="width: 100%; height: 20px"></div>


<div class="container2" (scroll)="onWindowScroll($event);">


        <div *ngFor="let scontrino of tavoliVuoti; index as i" style="height: 0px">

            <tab-card class="" stato="Apert" [totale]="calcolaTotaleOrdine(findTavolo(i)?.ordine)" [fonte]="findTavolo(i)?.fonte" [nome]="findTavolo(i)?.displayName" [numero]="i" [statoPagato]="findTavolo(i)?.statoPagato" [bloccato]="findTavolo(i)?.bloccato">
            </tab-card>

        </div>

</div>




