import {Inject, Injectable, Optional} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {CategoriesModel} from '../../models/menu/categories.model';

@Injectable({
  providedIn: 'any'
})
export class UserDataService{
  private dbPath = '/users';

  tutorialsRef: AngularFirestoreCollection<any[]>;

  constructor(private db: AngularFirestore,
              @Inject('ristorante') @Optional() private ristorante?: string,
              @Inject('raccolta') @Optional() private raccolta?: string,
  ) {
    this.tutorialsRef = db.collection(this.dbPath);
  }

  getAll(): AngularFirestoreCollection<any> {
    return this.tutorialsRef;
  }

  getDoc(id: string): AngularFirestoreDocument<any> {
    console.log("ciaociao getdoc: ", id);
    return this.tutorialsRef.doc(id);
  }

  getCollection(id: string): AngularFirestoreDocument<any> {
    return this.tutorialsRef.doc(id);
  }

  create(tutorial: any, id: string): any {
    return this.tutorialsRef.doc(id).set({ ...tutorial });
  }

  // Aggiornamento del metodo update per preservare i dati esistenti
  update(id: string, data: any): Promise<void> {
    // Recuperiamo il documento esistente per preservare i campi non inclusi nell'aggiornamento
    return this.tutorialsRef.doc(id).get().toPromise().then((doc) => {
      if (doc.exists) {
        const existingData = doc.data();

        // Uniamo i nuovi dati con i dati esistenti
        const mergedData = { ...existingData, ...data };

        // Aggiorniamo il documento con i dati uniti
        return this.tutorialsRef.doc(id).update(mergedData);
      } else {
        // Se il documento non esiste, creiamo un nuovo documento
        return this.tutorialsRef.doc(id).set(data);
      }
    });
  }

  delete(id: string): Promise<void> {
    return this.tutorialsRef.doc(id).delete();
  }
}
