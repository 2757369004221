
<section class="web_homePage" style='scroll-behavior: smooth;' (scroll)="onWindowScroll($event);">

<!-- >  <-->
<!--div  class="container2" *ngIf="scrollato == false" style="transition: 300ms ease; height: 220px; background-size: cover; background-position:50%;" [ngStyle]="{backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/menu%2FRectangle_cc%402x.png?alt=media&token=bc55165b-a5ef-4786-adc7-f21d7a9d7fa0)'}">
  <div class="app" style="padding-bottom: 0px">
    <div (click)="clicco2()" style=" position: relative; margin-left: 25px; margin-top: 8px"><span class="store1" [ngStyle]="{background: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2Fwhite-back-arrow.png?alt=media&token=4812c7ff-640a-4e1c-b159-55920501cc26'}" style=" padding: 0px;"></span></div>
    <h1 style="font-size: 24px; margin-top: 14px; margin-left: 25px; color: white;">Ordine</h1>
    <div (click)="clicco3()" style=" position: relative; margin-right: 10px; margin-top: 8px"><span class="modifica" style=" padding: 0px;"></span></div>
  </div>
</div-->

  <div  class="container2" style="transition: 300ms ease; height: 220px; background-size: cover; background-position:50%;" [ngStyle]="{backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/sofia%2Flavalletta%2Fc9526300-9fab-43d1-8412-bd007c2e0c75.JPG?alt=media&token=c6212f98-49f0-4887-a6ef-29f0811fd837)'}">
    <div class="app" style="padding-bottom: 0px">
      <div (click)="clicco2()" style=" position: relative; margin-left: 25px; margin-top: 8px"><span class="store1" [ngStyle]="{background: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2Fwhite-back-arrow.png?alt=media&token=4812c7ff-640a-4e1c-b159-55920501cc26'}" style=" padding: 0px;"></span></div>
      <h1 style="font-size: 24px; margin-top: 14px; margin-left: 25px; color: white;">Ordine</h1>
      <!--div (click)="clicco3()" style=" position: relative; margin-right: 10px; margin-top: 8px"><span class="modifica" style=" padding: 0px;"></span></div-->
      <button mat-fab color="accent"
              style="z-index: 100; width: 1700%; border-radius: 15px; align-items: center; justify-content: center; margin-left: -1500%; margin-top: 100%; scale: 50%"
              class="edit-button"
              [class.mat-elevation-z2]="!isOver"
              [class.mat-elevation-z8]="isOver"
              (mouseover)="isOver = true"
              (mouseleave)="isOver = false"
              (click)="clicco3()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="42" height="35" viewBox="0 0 42 35" style="scale: 200%">
          <defs>
            <filter id="edit_menu" x="0" y="0" width="42" height="35" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="3" result="blur"/>
              <feFlood flood-opacity="0.161"/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#edit_menu)">
            <text id="edit_menu-2" data-name="edit menu" transform="translate(21 15)" fill="#fff" font-size="9" font-family="Montserrat-Bold, Montserrat" font-weight="700"><tspan x="-10.993" y="0">EDIT</tspan><tspan font-size="7"><tspan x="-11.277" y="6">MENU</tspan></tspan></text>
          </g>
        </svg>
      </button>
    </div>
  </div>


  <button mat-fab color="accent"
          style="z-index: 100; width: 85%; border-radius: 15px; display: flex; align-items: center; justify-content: center;"
          (click)="clickMenu($event)"
          class="menu-button"
          [class.mat-elevation-z2]="!isOver"
          [class.mat-elevation-z8]="isOver"
          (mouseover)="isOver = true"
          (mouseleave)="isOver = false"
          *ngIf="ifCarrello == true"
  >
    <span style=" margin-right: 0px; font-size: 15px">{{this.numeroElementi}} piatti per {{this.tot}} €</span>
    <i class="material-icons" (click)="clickMenu($event)" style=" transform: scale(1.8, 1.8); margin-right: auto;"></i>
  </button>


  <div  *ngIf="scrollato == true && ricercato == true" class="container2" style="transition: 1000ms ease; height: 60px; background-color: white; position: relative; position: -webkit-sticky; /* Safari */ position: sticky; top: 0px; z-index: 99;" >
    <div class="app" style="padding-bottom: 0px">
      <div (click)="clicco2()" style=" position: relative; margin-left: 25px; margin-top: 8px"><span class="backNero" [ngStyle]="{background: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2F507257.png?alt=media&token=f4f84beb-eb17-450d-8d3e-4fa1c7b514dd'}" style=" padding: 0px;"></span></div>
      <h1 style="font-size: 24px; margin-top: 14px; margin-left: 25px; color: black;">Ordine</h1>
    </div>
  </div>

  <div  *ngIf="scrollato == true && ricercato == false" class="container2" style="transition: 1000ms ease; height: 130px; background-color: white; position: relative; position: -webkit-sticky; /* Safari */ position: sticky; top: 0px; z-index: 99;" >
    <div class="app" style="padding-bottom: 0px">
      <div (click)="clicco2()" style=" position: relative; margin-left: 25px; margin-top: 8px"><span class="backNero" [ngStyle]="{background: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2F507257.png?alt=media&token=f4f84beb-eb17-450d-8d3e-4fa1c7b514dd'}" style=" padding: 0px;"></span></div>
      <h1 style="font-size: 24px; margin-top: 14px; margin-left: 25px; color: black;">Ordine</h1>
    </div>
    <div class="categorie">

      <ul #widgetsContent class="hs" style="width: 114%; padding-left: 0px; padding-right: 0px; margin-left: -30px; scroll-behavior: smooth; box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2); transition: 1s; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 3px 6px 5px #0000000D; opacity: 100%; border-bottom: 1px double lightgray;" >
        <!--cathegoriesCard *ngFor="let cathegory of links" [link]="cathegory"></cathegoriesCard-->
        <button *ngFor="let cathegories of menu, let i = index" id="buttonCategoria{{i}}"  class="categorie" (click)="scroll(i)" style="margin-bottom: 0px; text-align: center; display: inline-block; padding-left:6px; ">{{cathegories.categoria}}</button>
      </ul>
    </div>
  </div>





<!--menuContainer *ngIf="scrollato==false" style="transition: 300ms ease;" link="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fd5f6b64a-81c8-41e1-a36a-01847634.jpg?alt=media&token=4e30f9c6-e183-4879-8dbc-4593ced7e4ca" title="Fuego-BBQ" subtitle="Riccione, 29 Gennaio"  [scannerEnabled]=scannerEnabled tavolo="{{this.numeroTavolo}}" coperto="2.50€"></menuContainer-->
  <menuContainer style="transition: 300ms ease;" link="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fd5f6b64a-81c8-41e1-a36a-01847634.jpg?alt=media&token=4e30f9c6-e183-4879-8dbc-4593ced7e4ca" title="{{ristorante}}" subtitle="Riccione, 29 Gennaio"  [scannerEnabled]=scannerEnabled tavolo="{{this.numeroTavolo}}" coperto="2.50€"></menuContainer>


<!--div *ngIf="this.scrollato == false;" class="sb-example-1 shadow" style="margin-top: 20px; transition: 300ms ease;">
  <div class="search">
    <button type="submit" class="searchButton2">
      <i class="fa fa-search"></i>
    </button>
    <input type="text" class="searchTerm" placeholder="Cerca" style="border-radius: 10px">

  </div>
</div-->

  <div class="sb-example-1 shadow" style="">
    <div class="search">
      <button type="submit" class="searchButton2">
        <i class="fa fa-search"></i>
      </button>
      <input type="text" class="searchTerm" placeholder="Cerca" [(ngModel)]="this.ricerca" (ngModelChange)="valuechange($event)">
      <button type="submit" class="searchButton">
      </button>
    </div>
  </div>



  <span *ngIf="ricercato == false" >
<div *ngFor="let categoria of menu, let i = index"  >
   <p #cacaca id="{{i}}" style="text-align: left; max-width: 95%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: bold; font-size: 28px; padding-left: 0px; margin: 0px; margin-top:20px; margin-left: 4%; white-space: normal; word-break: normal; word-wrap: break-word;  overflow-wrap: break-word; hyphens: auto; position: sticky; ">{{categoria.categoria}} <t *ngIf="categoria.prezzo"> {{categoria.prezzo}} € </t> <t *ngIf="categoria.persone" style="font-weight: normal; font-size: 24px;"> <br> ( minimo {{categoria.persone}} persone ) </t></p>
   <div class="container menu" id="menuListaPiatti">
  <div class="row" style=" padding-left: 0px; margin-bottom: 0px" *ngFor="let piatto of categoria.menu" (click)="clickPiatto(piatto.id)">
    <menuCard *ngIf="categoria.price" class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; margin-bottom:0px;"  [nome]="piatto.title" [ingredienti]="piatto.title" [link]="piatto.image_link" prezzo="INCLUSO" ></menuCard>
    <menuCard *ngIf="!categoria.price" class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; margin-bottom:0px;"  [nome]="piatto.title" [ingredienti]="piatto.title" [link]="piatto.image_link" [prezzo]="piatto.price" ></menuCard>

    <div *ngIf="carrelloTmp !== undefined">
        <div1 *ngFor="let piattoCarrello of carrelloTmp.piatti, let indexCarrello = index" style="margin-top: 0px; width: 100%">


          <button (click)="rimuoviElementoCarrello(indexCarrello)" *ngIf="piattoCarrello.id == piatto.id && carrelloTmp.ristorante == ristorante"  class="detail-button minus-button">-</button>

          <menuDetailCard *ngIf="piattoCarrello.id == piatto.id && carrelloTmp.ristorante == ristorante" class="" style="padding-left: 0px; margin-left: 0px; padding-right: 0px; margin-bottom:0px; margin-top: 10px; padding-bottom: 10px"  [nome]="piattoCarrello.nome" [ingredienti]="piattoCarrello.descrizione" [link]="piatto.link" [prezzo]="getPrezzo(indexCarrello)" [quantita]="piattoCarrello.quantita" [user]=user [piatto]=piatto [carrello] = false></menuDetailCard>

          <button (click)="aggiungiElementoCarrello(indexCarrello)" *ngIf="piattoCarrello.id == piatto.id && carrelloTmp.ristorante == ristorante" class="detail-button plus-button">+</button>
        </div1>
    </div>


  </div>
</div>
</div>
</span>


<span *ngIf="ricercato == true" style="margin-top: 20px">
    <div class="container menu" id="menuListaPiatti">
  <div class="row" style=" padding-left: 0px; margin-bottom: 0px" *ngFor="let piatto of menuFiltrato[0]" >
    <menuCard (click)="clickPiatto(piatto.nome)" class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; margin-bottom:0px;"  [nome]="piatto.nome" [ingredienti]="piatto.descrizione" [link]="piatto.link" [prezzo]="piatto.prezzo" ></menuCard>

    <div1 *ngFor="let piattoCarrello of carrelloTmp.piatti, let indexCarrello = index" style="margin-top: 0px; width: 100%">


      <button (click)="rimuoviElementoCarrello(indexCarrello)" *ngIf="piattoCarrello.id == piatto.id && carrelloTmp.ristorante == ristorante" mat-fab
              style="background-color:rgba(0, 0, 0, 0);; margin-top: 40px; margin-left: 3%; width: 30px; height: 30px; border-radius: 35px; align-items: center; justify-content: center; border-width: 3px; border-color: black; margin-right: 20px; display: flex; position: absolute;">
        <span style="font-size: 15px; color: black; margin-top: -100px">-</span>
      </button>

      <menuDetailCard *ngIf="piattoCarrello.id == piatto.id && carrelloTmp.ristorante == ristorante" class="" style="padding-left: 0px; margin-left: 0px; padding-right: 0px; margin-bottom:0px; margin-top: 10px; padding-bottom: 10px"  [nome]="piattoCarrello.nome" [ingredienti]="piattoCarrello.descrizione" [link]="piatto.link" [prezzo]="getPrezzo(indexCarrello)" [quantita]="piattoCarrello.quantita"></menuDetailCard>

      <button (click)="aggiungiElementoCarrello(indexCarrello)" *ngIf="piattoCarrello.id == piatto.id && carrelloTmp.ristorante == ristorante" mat-fab
              style="background-color:rgba(0, 0, 0, 0);; margin-top: -60px; margin-left: 88%; width: 25px; height: 30px; border-radius: 35px; align-items: center; justify-content: center; border-width: 3px; border-color: black; margin-right: 20px; display: flex; position: absolute;">
        <span style="font-size: 15px; color: black; margin-top: -100px">+</span>
      </button>
    </div1>

  </div>
</div>
</span>


</section>
