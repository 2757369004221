

<div class="containerDettaglioScontrino">

<div class="container2" style="">
  <div class="app" style="padding-bottom: 0px">
    <div (click)="buongiorno($event)" style=" position: fixed; margin-left: 25px; margin-top: 8px; z-index: 99; pointer-events: auto;"><span class="store1" [ngStyle]="{background: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2F507257.png?alt=media&token=f4f84beb-eb17-450d-8d3e-4fa1c7b514dd'}" style=" padding: 0px;"></span></div>
    <h1 style="font-size: 24px; margin-top: 14px; margin-left: 25px">EditCategorie</h1>
    <div (click)="buongiorno($event)" style=" position: fixed; margin-left: 25px; margin-top: 8px; z-index: 99; pointer-events: auto;"><span class="store2" [ngStyle]="{background: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/menu%2Fcameriere_call.png?alt=media&token=e9c41e62-8677-4a02-a8b5-78e98d8c645d'}" style=" padding: 0px;"></span></div>
  </div>
</div>



<menuItemHeader class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [title]="piatto.nome" [subtitle]="piatto.descrizione"  ></menuItemHeader>

</div>

<button mat-fab color="accent"
        style="z-index: 100; width: 33%; border-radius: 35px; display: flex; align-items: center; justify-content: center;"
        (click)="clickMenu($event)"
        class="menu-button"
        [class.mat-elevation-z2]="!isOver"
        [class.mat-elevation-z8]="isOver"
        (mouseover)="isOver = true"
        (mouseleave)="isOver = false">
  <span style=" margin-right: 20px; font-size: 15px">{{prezzo}} €</span>
  <i class="material-icons" (click)="clickMenu($event)" style=" transform: scale(1.8, 1.8); margin-right: auto;">check</i>
</button>

  <div>
    <p style="text-align: left; width:80%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: 500; font-size: 18px; padding-left: 0px; margin: 0px; white-space: nowrap; text-overflow: ellipsis; color: Black; margin-top: 200px; margin-bottom: 0px; margin-left: 20px">Scegli l'impasto!</p>
    <p style="text-align: left; width:80%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: bold; font-size: 16px; padding-left: 0px; margin: 0px; white-space: nowrap; text-overflow: ellipsis; color: grey;  margin-bottom: 0px; margin-left: 20px; font-weight: normal">Opzionale</p>
    <div class="container2NotScrolled" id="lista-scontrino-dettaglio" style="pointer-events: auto; margin-top: 15px">
      <div class="row" style="padding-top: 0%; padding-left: 0px" *ngFor="let opzione of piattoDatabase.opzioni, let i = index" (click)="selezionePrimariaMetodo(i, opzione)">
        <dettagliOrdinazioneMenuCard *ngIf="opzione.prezzo" class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="opzione.ingrediente" [luogo]="opzione.ingrediente"  [costo]="opzione.prezzo" [selezionato]="opzione.selezionato" nominativo="MenuPrimario" ifCosto='true'></dettagliOrdinazioneMenuCard>
        <dettagliOrdinazioneMenuCard *ngIf="!opzione.prezzo" class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="opzione.ingrediente" [luogo]="opzione.ingrediente" [selezionato]="opzione.selezionato" nominativo="MenuPrimario" ifCosto='false'></dettagliOrdinazioneMenuCard>

      </div>
    </div>
  </div>
<div>
  <p style="text-align: left; width:80%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: 500; font-size: 18px; padding-left: 0px; margin: 0px; white-space: nowrap; text-overflow: ellipsis; color: Black; margin-top: 15px; margin-bottom: 0px; margin-left: 20px">Modifica gli ingredienti base!</p>
  <p style="text-align: left; width:80%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: bold; font-size: 16px; padding-left: 0px; margin: 0px; white-space: nowrap; text-overflow: ellipsis; color: grey;  margin-bottom: 0px; margin-left: 20px; font-weight: normal">Opzionale</p>
  <div class="container2NotScrolled" id="lista-scontrino-dettaglio" style="pointer-events: auto; margin-top: 15px">
    <div class="row" style="padding-top: 0%; padding-left: 0px" *ngFor="let opzione of ristoranteDatabase.opzioni">
      <dettagliOrdinazioneMenuCard2 class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="opzione.ingrediente" [luogo]="opzione.ingrediente" [link]="opzione.link" [costo]="opzione.prezzo" [quantita]="opzione.quantita" seleziona="no"></dettagliOrdinazioneMenuCard2>
    </div>
  </div>
</div>






