import {Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoriesModel} from '../../shared/models/menu/categories.model';
import {newArray} from '@angular/compiler/src/util';
import {MenuService} from '../../shared/services/firestore/menu.service';
import {map} from 'rxjs/operators';
import {firebase} from '@firebase/app';
import {FirestoreService} from '../../shared/services/firestore/firestore.service';
import {User} from '../../shared/services/user';

@Component({
  selector: 'app-categorie',
  templateUrl: './menu.html',
  styleUrls: ['./menu.css'],
  providers: [
    MenuService,
    {
      provide: 'ristorante',
      useValue: 'menu_test',
    },
    {
      provide: 'raccolta',
      useValue: 'menu_test',
    },
  ],
})
export class Menu implements OnInit {

  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private menuService: MenuService, private carrelloService: FirestoreService, private route: ActivatedRoute) {

    this.route.params.subscribe(params => {
        console.log(params);
        if (params.ristorante) {
          this.ristorante = params.ristorante;
        }
        if (params.tavolo) {
          this.numeroTavolo = params.tavolo;
        }
      }
    );

    this.retriveMenu();


    // this.retriveCarrello();
  }

  carrello = new Array();

  prezzoCarrello;

  ifCarrello = false;

  carrelloTmp;


  ricerca = '';

  ricercato = false;


  items = [
    'Item 0',
    'Item 1',
    'Item 2',
    'Item 3',
    'Item 4',
    'Item 5',
    'Item 6',
    'Item 7',
  ];


  tutorials?: CategoriesModel[];
  currentTutorial?: CategoriesModel;
  currentIndex = -1;
  title = '';
  menu = new Array();
  menuFiltrato = new Array();

  user: User;


  isOver = false;


  ristorante = 'ciao';
  numeroTavolo = 0;


  immagineProfilo = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/sofia%2Flavalletta%2Fc9526300-9fab-43d1-8412-bd007c2e0c75.JPG?alt=media&token=c6212f98-49f0-4887-a6ef-29f0811fd837';

  nome = 'Marco Polo';

  ingredienti = 'Visualizza Profilo';

  prezzo = 93263269631;

  scrollato = false;

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];

  public scannerEnabled = false;

  public categoria = 0;
  public categoriaBefore = 0;

  public porcodioPrima = 0;
  public porcodioDopo = 0;
  public prece = 0;
  public selez = 0;

  tot = 0;
  numeroElementi = 0;

  @ViewChildren('cacaca') testDiv: QueryList<any>;
  isTestDivScrolledIntoView: boolean;

  @ViewChild('widgetsContent') widgetsContent: ElementRef;

  ngOnInit(): void {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.retriveCarrello();
      } else {
        // No user is signed in.
      }
    });


  }


  valuechange(newValue) {
    console.log(this.ricerca);
    this.menuFiltrato = new Array();
    this.menuFiltrato = this.filterByValue(this.menu, newValue);
    console.log('menuCard filtrato');
    console.log(this.menuFiltrato);
    if (newValue.length > 0) {
      this.ricercato = true;
    } else {
      this.ricercato = false;
    }
    // this.scontrini.filter(t => t.nome === newValue);

  }

  filterByValue(array, stringa) {


    const result = new Array;

    /*
    result.concat(array.forEach(element => {

      console.log('element');
      console.log(element);
      element.menuCard.filter(o => {
        console.log('o');
        console.log(o);
        try {
          Object.keys(o).some(k => o[k].toLowerCase().includes(stringa.toLowerCase));
        }catch {}
      });

    }));

     */

    array.forEach(element => {
      console.log('element');
      console.log(element.menu);
      console.log('element filtrato');
      const filtrato = this.filterByValueInside(element.menu, stringa);
      console.log(filtrato);
      if (filtrato.length > 0) {
        result.push(filtrato);
      }
    });
    console.log('result');
    console.log(result);
    const risultato = new Array();
    risultato.push(new Array());
    result.forEach(element => risultato[0] = risultato[0].concat(element));
    console.log('risultato');
    console.log(risultato);
    return risultato;
  }

  filterByValueInside(arrayy, stringaa) {

    console.log('filtro: ', arrayy, stringaa);

    // console.log('array');
    // console.log(array);
    return arrayy.filter(o => Object.keys(o).some(k => o[k].toString().toLowerCase().includes(stringaa.toLowerCase())));
  }


  retriveMenu(): void {

    this.menuService.getDoc(this.ristorante).get().subscribe(
      data => {
        if (data.data() !== undefined) {



          console.log("ho trovato: ", data.data())


          let categories = []
          let i  = 1
          // Raggruppa i piatti per categoria
          data.data().items.forEach(item => {
            if(categories.findIndex(categoria => categoria.categoria == item.category) < 0)
            {
              categories.push(
                  {
                    categoria: item.category,
                    id: i,
                    menu: []
                  }
              )
              i = i+1
            }
          })

          categories.forEach(categoria => {

            data.data().items.forEach(item => {
              if(item.category == categoria.categoria)
              {
                categoria.menu.push(item)
              }
            })

          })

          console.log("ho trovato: ", categories)




          /*
          console.log('menuCard: ', this.menu);
          console.log('ho trovato: ', data.data().menu);

          let i = 0;
          // tslint:disable-next-line:forin
          for (const x in data.data().menu) {
            this.menu.push(data.data().menu[i]);
            i = i + 1;
          }

           */

          this.menu = this.menu.concat(categories);

          console.log("ho trovato menu: ", this.menu)
          // this.menuCard = data.data();
        }
      }
    );

  }

  retriveCarrello(): void {

    console.log(firebase.auth().currentUser);
    console.log(firebase.auth().currentUser.uid);

    this.carrelloService.getDoc(firebase.auth().currentUser.uid).get().subscribe(
      data => {
        if (data.data() !== undefined) {

          this.user = data.data();

          console.log('menuCard: ', this.carrello);
          console.log('ho trovato2: ', data.data());

          this.carrelloTmp = data.data().carrello;

          console.log(this.carrelloTmp);
          if (this.carrelloTmp.ristorante !== this.ristorante) {
            this.ifCarrello = false;
          } else {
            this.ifCarrello = true;


            this.calcolaTotale();


          }

          /*
          let i = 0;
          for (const x in data.data()) {
            this.carrello.push(data.data()[i]);
            i = i + 1;
          }
          // @ts-ignore
          // this.menuCard = CategoriesModel[data.data];
          // @ts-ignore
          this.menuCard.concat(data.data);
          // this.menuCard = data.data();

           */


        }
      }
    );
  }

  clickMenu(event): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    this.router.navigate(['/carrello']);
    event.stopPropagation();
  }

  // tslint:disable-next-line:typedef
  dropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.items,
      event.previousIndex,
      event.currentIndex
    );
  }

  clicco2(): void {
    this.router.navigate(['/tavoli']);
  }


  clicco3(): void {
    this.router.navigate([this.ristorante + '/menu/modifica/modifica']);
  }

  clickPiatto(piatto?: string): void {
    this.router.navigate([this.ristorante + '/menu/' + piatto]); // , { skipLocationChange: true});
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e): void {
    let offset = 300;
    if (this.ricercato === true) {
      offset = 280;
    } else {
      offset = 310;
    }
    // 350 with search bar
    // 280 without search bar
    if (window.pageYOffset > offset) {
      console.log('scrollato');

      const element3 = document.getElementById('menuListaPiatti');
      element3.classList.add('menuScrolled');
      element3.classList.remove('menu');
      this.scrollato = true;

    } else {
      if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {

        console.log('scrollato2');
      } else {
        console.log('scrollato3');
        this.scrollato = false;
        const element3 = document.getElementById('menuListaPiatti');
        element3.classList.add('menu');
        element3.classList.remove('menuScrolled');
      }
    }

    console.log(this.testDiv);


    if (this.ricercato === false) {

      this.testDiv.forEach((child) => {

        if (child) {
          const test = child.nativeElement.id;
          const rect = child.nativeElement.getBoundingClientRect();
          const topShown = rect.top >= 0;
          const bottomShown = rect.bottom <= window.innerHeight;
          this.isTestDivScrolledIntoView = topShown && bottomShown;


          console.log('top/bottom: ' + (rect.top / rect.bottom));

          if (this.isTestDivScrolledIntoView) {
            this.porcodioPrima = window.pageYOffset;
            this.porcodioDopo = window.pageYOffset;
            this.categoria = test;
            this.categoriaBefore = test;


          }


          this.porcodioDopo = window.pageYOffset;


          if ((this.categoria === test) && (((rect.top - 100) / rect.bottom) <= 0.75)) {
            console.log('altezza su');


            if (this.widgetsContent !== undefined) {
              this.widgetsContent.nativeElement.scrollTo((test * 100), 0);
            }

            const element = document.getElementById('buttonCategoria' + test);
            if (element !== null) {

              let conta = 0;
              this.menu.forEach(
                cathegories => {
                  if (conta !== test) {
                    const elemento = document.getElementById('buttonCategoria' + conta);
                    elemento.classList.remove('categorieVisibile');
                    elemento.classList.add('categorie');
                  }
                  conta++;
                }
              );

              element.classList.remove('categorie');
              element.classList.add('categorieVisibile');

            }

            const element2 = document.getElementById('buttonCategoria' + (test - 1));
            if (element2 !== null) {


              element2.classList.remove('categorieVisibile');
              element2.classList.add('categorie');
            }

          } else if ((this.categoria === test) && (((rect.top - 100) / rect.bottom) <= 1)) {
            console.log('altezza giu');

            if (this.widgetsContent !== undefined) {
              this.widgetsContent.nativeElement.scrollTo(((test - 1) * 100), 0);
            }

            const element = document.getElementById('buttonCategoria' + (test - 1));
            if (element !== null) {

              let conta = 0;
              this.menu.forEach(
                cathegories => {
                  if (conta !== (test - 1)) {
                    const elemento = document.getElementById('buttonCategoria' + (conta));
                    elemento.classList.remove('categorieVisibile');
                    elemento.classList.add('categorie');
                  }
                  conta++;
                }
              );


              element.classList.remove('categorie');
              element.classList.add('categorieVisibile');
            }

            const element2 = document.getElementById('buttonCategoria' + test);
            if (element2 !== null) {
              element2.classList.remove('categorieVisibile');
              element2.classList.add('categorie');
            }

          }


        }


        console.log(this.isTestDivScrolledIntoView);
      });

    }

  }


  scroll(id: any): void {
    console.log(`scrolling to ${id}`);

    const yOffset = -150;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});


    // const el = document.getElementById(id).scrollIntoView();
    // el.scrollIntoView({behavior: 'smooth'});
  }


  rimuoviElementoCarrello(i): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    console.log('carrello: ', this.carrelloTmp);
    console.log('index: ', i);

    if (this.carrelloTmp.piatti[i].quantita === 1) {
      this.carrelloTmp.piatti.splice(i, 1);
      console.log('carrelo con rimozione: ', this.carrelloTmp);

    } else {
      this.carrelloTmp.piatti[i].quantita--;
    }

    this.calcolaTotale();

    this.salva();


    event.stopPropagation();
  }

  aggiungiElementoCarrello(i): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    console.log('carrello: ', this.carrelloTmp);
    console.log('index: ', i);

    this.carrelloTmp.piatti[i].quantita = this.carrelloTmp.piatti[i].quantita + 1;

    this.calcolaTotale();

    this.salva();

    event.stopPropagation();
  }

  getPrezzo(i): any {
    // console.log('ciaobiuyew giuye231<giue23<y789e23<');
    // console.log("carrello: ", this.carrelloTmp);
    // console.log("index: ", i);


    // console.log('piatto', this.carrelloTmp.piatti[i]);

    let prezzoPiatto = this.carrelloTmp.piatti[i].prezzo;

    if (this.carrelloTmp.piatti[i].opzioni) {
      this.carrelloTmp.piatti[i].opzioni.forEach(opzione => {

        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }

    return prezzoPiatto;
  }


  calcolaTotale(): void {

    this.numeroElementi = 0;
    this.tot = 0;

    this.carrelloTmp.piatti.forEach(piatto => {
      console.log('piatto', piatto);
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;

      if (piatto.opzioni) {
        piatto.opzioni.forEach(opzione => {

          prezzoPiatto = prezzoPiatto + opzione.prezzo;
        });
      }
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);


    });
  }


  salva(): void {
    console.log('Salva carrello: ', this.carrelloTmp);

    console.log('user: ', this.user);

    this.user.carrello = this.carrelloTmp;

    console.log('user da salvare: ', this.user);

    this.carrelloService.delete(this.user.uid);

    // this.menuService.create(this.menuCard, this.ristorante);

    console.log('user da salvare: ', this.user);

    const userDasalvareSalva = {

      uid: this.user.uid,
      email: this.user.email,
      displayName: this.user.displayName,
      photoURL: this.user.photoURL,
      emailVerified: this.user.emailVerified,
      allergeni: this.user.allergeni,
      scontrini: this.user.scontrini,
      carrello: this.carrelloTmp,

    };

    console.log('user da salvare salva: ', userDasalvareSalva);



    this.carrelloService.create(userDasalvareSalva, this.user.uid);
    // this.router.navigate(['/menuCard']);
  }

}



/*
scroll(id: any): void {
  console.log(`scrolling to ${id}`);
  let el = document.getElementById(id);
  //el.scrollIntoView({behavior: 'smooth'});
  el.scrollIntoView({ behavior: 'smooth' });

}

 */
