
import {CommonService} from "../firestore/common.service";
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable()
export class DataServiceGenerico {

  private selectedMenuSubject = new BehaviorSubject<string>('Valletta');
  selectedMenu$: Observable<string> = this.selectedMenuSubject.asObservable();

  constructor(
  ) {
    this.loadSelectedMenuFromFirebase();
  }


  getAvailableMenus(): Observable<string[]> {
    return new Observable((observer) => {
      const db = firebase.firestore();
      const menuRef = db.collection('menu_test');

      menuRef.get().then((snapshot) => {
        const menuNames: string[] = [];
        snapshot.forEach(doc => {
          menuNames.push(doc.id);  // Push the document id (menu name)
        });
        observer.next(menuNames);
        observer.complete();
      }).catch(error => {
        console.error('Error fetching menus:', error);
        observer.error(error);
      });
    });
  }

  setSelectedMenu(menuName: string) {
    this.selectedMenuSubject.next(menuName);  // Update the BehaviorSubject

    const db = firebase.firestore();
    const menuSceltoRef = db.collection('menu_scelto').doc('menu_scelto');

    // Save the selected menuName to Firebase
    menuSceltoRef.set({
      selectedMenu: menuName
    }).then(() => {
      console.log('menuName salvato con successo su Firestore!');
    }).catch((error) => {
      console.error('Errore durante il salvataggio di menuName su Firestore:', error);
    });
  }

  private loadSelectedMenuFromFirebase(): void {
    const db = firebase.firestore();
    const menuSceltoRef = db.collection('menu_scelto').doc('menu_scelto');

    // Retrieve the saved menuName from Firebase
    menuSceltoRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        if (data && data.selectedMenu) {
          this.selectedMenuSubject.next(data.selectedMenu);  // Update the BehaviorSubject with the value from Firebase
        } else {
          console.error('Campo selectedMenu non trovato.');
        }
      } else {
        console.error('Documento menu_scelto non trovato.');
      }
    }).catch(error => {
      console.error('Errore nel recupero del menuName da Firestore:', error);
    });
  }


  // Create a new menu in Firebase
  createMenu(newMenuName: string): Promise<void> {
    const db = firebase.firestore();
    return db.collection('menu_test').doc(newMenuName).set({
      items: [],  // Initialize with an empty array of items
      nome_ristorante: newMenuName
    });
  }

  setUser(u)
  {
    user = u
  }

  getUser()
  {
    return user;
  }

  salva()
  {
    this.camerieriSubject.next(camerieri);
  }

  subtractCameriere(cameriere: any, piatto: any)
  {

    let filteredCamerieri = camerieri.findIndex(cameriere3 => cameriere3.ordine == cameriere)

    let cameriere2 = camerieri[filteredCamerieri]

    if(cameriere2.hasOwnProperty('carrello')? cameriere2.carrello?.hasOwnProperty(piatto.title.replace(/ /g,'')): false)
    {
      if(cameriere2.carrello[piatto.title.replace(/ /g,'')].quantita == 1)
      {
        delete cameriere2.carrello[piatto.title.replace(/ /g,'')]
      }
      else
      {
        cameriere2.carrello[piatto.title.replace(/ /g,'')].quantita--;
      }
    }

    camerieri = camerieri.filter(item => !(item.ordine == cameriere2.ordine))
    camerieri.push(cameriere2)
    console.log("received camerieri 2: ", camerieri)
    console.log("received camerieri 2: ", cameriere2)


    camerieri = camerieri.sort((a, b) => b.ordine - a.ordine)
    ordine = cameriere2

    this.OrdineSubject.next(ordine);

    this.camerieriSubject.next(camerieri);


  }


  updateCameriere2(cameriere: any, piatto: any){
    console.log("received cameriere: ", cameriere)
    console.log("received camerieri: ", camerieri)

    console.log("received piatto: ", piatto)

    let filteredCamerieri = camerieri.findIndex(cameriere3 => cameriere3.ordine == cameriere)
    console.log("received filtered camerieri: ", filteredCamerieri)
    camerieri[filteredCamerieri].carrello = piatto;

    console.log("received ciaociao2")

    ordine = camerieri[filteredCamerieri]

    camerieri = camerieri.sort((a, b) => b.ordine - a.ordine)

    this.OrdineSubject.next(ordine);

    this.camerieriSubject.next(camerieri);
  }


  updateCameriere(cameriere: any, piatto: any){
    console.log("received cameriere: ", cameriere)
    console.log("received camerieri: ", camerieri)

    console.log("received piatto: ", piatto)

    let filteredCamerieri = camerieri.findIndex(cameriere3 => cameriere3.ordine == cameriere)
    console.log("received filtered camerieri: ", filteredCamerieri)
    let cameriere2 = camerieri[filteredCamerieri]

    console.log("received cameriere2: ", cameriere2)
    if(cameriere2.hasOwnProperty('carrello')? cameriere2.carrello?.hasOwnProperty(piatto.title.replace(/ /g,'')): false)
    {
      cameriere2.carrello[piatto.title.replace(/ /g,'')].quantita++;
    }
    else
    {
      if(!cameriere2.hasOwnProperty('carrello'))
      {
        console.log("received ciaociao: ", piatto.title.replace(/ /g,''))
        let nome = piatto.title.replace(/ /g,'');
        console.log("received cameriere22: ", cameriere2)

        cameriere2['carrello'] = {}
        cameriere2['carrello'][nome] = piatto;
        console.log("received ciaociao3")

        cameriere2.carrello[nome].quantita = 1;
        cameriere2.carrello[nome].price = +cameriere2.carrello[nome].price;
        cameriere2.carrello[nome].prezzo = +cameriere2.carrello[nome].price


        console.log("received ciaociao4: ", cameriere2)
      }else
      {
        let nome = piatto.title.replace(/ /g,'');
        console.log("received cameriere22: ", cameriere2)

        cameriere2['carrello'][nome] = piatto;
        console.log("received ciaociao3")

        cameriere2.carrello[nome].quantita = 1;
        cameriere2.carrello[nome].price = +cameriere2.carrello[nome].price;
        cameriere2.carrello[nome].prezzo = +cameriere2.carrello[nome].price
      }
    }
    console.log("received ciaociao2")



    camerieri = camerieri.filter(item => !(item.ordine == cameriere2.ordine))
    camerieri.push(cameriere2)
    console.log("received camerieri 2: ", camerieri)
    console.log("received camerieri 2: ", cameriere2)


    ordine = cameriere2

    camerieri = camerieri.sort((a, b) => b.ordine - a.ordine)


    this.OrdineSubject.next(ordine);

    this.camerieriSubject.next(camerieri);
  }

  setCamerieri(camerieri2: any)
  {
    camerieri = camerieri2;
    this.camerieriSubject.next(camerieri);
  }


  private camerieriSubject = new BehaviorSubject<any>(0);
  camerieriSubjectItem$ = this.camerieriSubject.asObservable();

  //ordine da salvare
  private OrdineSubject = new BehaviorSubject<any>(0);
  OrdineSubjectItem$ = this.OrdineSubject.asObservable();


  getCamerieri()
  {
    return camerieri.filter(ordine => ordine.hasOwnProperty('fonte')? ordine.fonte == 1: false).filter(ordine => ordine.hasOwnProperty('statoPagato')? ordine.fonte == 0: false);
  }

  setFilterFonte(filterFonteInterno)
  {
    filterFonte = filterFonteInterno
  }


  setFilterPagato(filterPagatoInterno)
  {
    filterPagato = filterPagatoInterno
  }

  getRistorante(): string {
    return this.selectedMenuSubject.getValue();  // This will return the value stored in the BehaviorSubject
  }


  getAllergeni()
  {
    return allergeni
  }

  getRistoranti()
  {
    return ristoranti
  }

  setRistoranti(ristoranti2)
  {
    ristoranti = ristoranti2
  }

  getGruppi()
  {
    return gruppi
  }
  setGruppi(gruppi2)
  {
    gruppi = gruppi2
  }

  getCollegamenti()
  {
    return collegamenti
  }
  seCollegamenti(gruppi2)
  {
    collegamenti = gruppi2
  }

  setImmagini(immagini2)
  {
    immagini = immagini2
  }

  getImmagini()
  {
    return immagini
  }

}

let immagini = {}

let user = {}

let camerieri = []

let ordine = {}


let filterPagato = 'Non Pagato'
let filterFonte = 'Cassa'


let ristorante = 'Valletta'


let allergeni = [
  "glutine",
  "latticini",
  "crostacei",
  "uova",
  "arachidi",
  "soia",
  "frutta con guscio",
  "sedano",
  "senape",
  "sesamo",
  "lupini",
  "molluschi",
  "Anidride solforosa",
]


let ristoranti = [
  {
    nome: 'Befane',
    piva: '981237983732971',
    email: 'befane@fillapp.it',
    permessi: ['']
  },
  {
    nome: 'Bagno 44',
    piva: '981237983732971',
    email: 'bagno44@fillapp.it',
    permessi: ['']
  },
  {
    nome: 'Bagno 23',
    piva: '981237983732971',
    email: 'bagno23@fillapp.it',
    permessi: ['']
  },
  {
    nome: 'Stazione bologna',
    piva: '981237983732971',
    email: 'Bologna@fillapp.it',
    permessi: ['']
  },
]

let gruppi = [
  {
    nome: 'Centri Commerciali',
    ristoranti: [
      {
        nome: 'Befane',
        piva: '981237983732971',
        email: 'befane@fillapp.it',
        permessi: ['']
      },
    ]
  },
  {
    nome: 'Mare',
    ristoranti: [
      {
        nome: 'Bagno 44',
        piva: '981237983732971',
        email: 'bagno44@fillapp.it',
        permessi: ['']
      },
      {
        nome: 'Bagno 23',
        piva: '981237983732971',
        email: 'bagno23@fillapp.it',
        permessi: ['']
      },
    ]
  },
  {
    nome: 'Stazione',
    ristoranti: [
      {
        nome: 'Stazione bologna',
        piva: '981237983732971',
        email: 'Bologna@fillapp.it',
        permessi: ['']
      },
    ]
  }
]


let collegamenti = [
  {
    nome: 'cannoleria',
    gruppo: [
      {
        nome: 'Centri Commerciali',
        ristoranti: []
      },
    ],
    ristoranti: [

    ]
  },
  {
    nome: 'fastfood',
    gruppo: [
      {
        nome: 'Mare',
        ristoranti: []
      },

    ],
    ristoranti: [

    ]
  },
  {
    nome: 'pasticceria_1',
    gruppo: [
      {
        nome: 'Stazione',
        ristoranti: []
      }
    ],
    ristoranti: [

    ]
  },
  {
    nome: 'pasticceria_2',
    gruppo: [
      ''
    ],
    ristoranti: [

    ]
  }
]

