import {ChangeDetectorRef,Component, HostListener, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-categorie',
  templateUrl: './scansiona-tavolo.html',
  styleUrls: ['./scansiona-tavolo.css']
})
  export class ScansionaTavolo implements OnInit {

  public ciao = "";
  public categoriesEnabled = false;
  public scannerEnabled = false;
  public transports: Transport[] = [];
  // messaggio per il debug sotto
  public information = '';
  currentItem = 'John Doe';
  currentDescription = 'Architect & Engineer';

  currentLink = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Flobster_1f99e.png?alt=media&token=c3fbe73b-01ec-415b-8a38-fedb138ec75a';

  ristoranti: { nome: string, descrizione: string, link: string }[] = [
    { nome: 'cannoleria', descrizione: 'Available', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/sofia%2Flavalletta%2Fc9526300-9fab-43d1-8412-bd007c2e0c75.JPG?alt=media&token=c6212f98-49f0-4887-a6ef-29f0811fd837' },
    { nome: 'fastfood', descrizione: 'Ready', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/sofia%2Flavalletta%2Fc9526300-9fab-43d1-8412-bd007c2e0c75.JPG?alt=media&token=c6212f98-49f0-4887-a6ef-29f0811fd837' },
    { nome: 'pasticceria_1', descrizione: 'Started', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/sofia%2Flavalletta%2Fc9526300-9fab-43d1-8412-bd007c2e0c75.JPG?alt=media&token=c6212f98-49f0-4887-a6ef-29f0811fd837' }
  ];

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];



  constructor(private http: HttpClient,
              private changeDetection: ChangeDetectorRef,
              private router: Router) {

  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e): void {

    // 350 with search bar
    // 280 without search bar
    /*if (window.pageYOffset > 280) {

    } else {
    }

     */

    if (window.innerHeight + window.scrollY + 1 >= document.body.scrollHeight) {

      console.log('ciaociaociaociao');
      //this.ristoranti.push({ nome: 'Fuego-BBQ', descrizione: 'Available', link: 'https://www.fuego-bbq.it/images/galleria/2.jpeg' });
      this.changeDetection.detectChanges();
      console.log(this.ristoranti);


      // you're at the bottom of the page
    }

  }

  scroll(id: any): void {
    console.log(`scrolling to ${id}`);
  }
  public scanSuccessHandler($event: any): void {
    // this.router.navigateByUrl('/scontrini');
    // this.scannerEnabled = false;

    this.information = $event;
    //this.ciao = this.information;
    console.log('scannerizzato: ', this.information );
    this.scannerEnabled = false;
    this.router.navigate(['/']);
  }

  ngOnInit(): void {
  }

  clicco(): void {
    // this.router.navigate(['/scan']);
    this.scannerEnabled = true;
  }

  clicco2(): void {
    this.scannerEnabled = false;
  }

  cliccoCategories(): void {
    this.categoriesEnabled = true;
  }

}
