<div class="login-page" >
    <div class="left-column">
        <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/login%2FFillApp%20solo%20logo.png?alt=media&token=ff3d2bda-1707-4d2a-9fab-01df4d628714" alt="Logo" class="logo-image">
    </div>
    <div class="right-column">
        <div class="login-form">
            <h2 style="text-align: center">Accedi</h2>
            <fieldset class="custom-fieldset">
                <legend style="width: 1%">User</legend>
                <div class="chart-container">
                    <input type="text" class="custom-input" placeholder="nome@fillapp.it" #userName required>
                </div>
            </fieldset>

            <fieldset class="custom-fieldset">
                <legend style="width: 1%">Password</legend>
                <div class="chart-container">
                    <input type="password" class="custom-input" placeholder="Psw#123456!!" #userPassword required>
                </div>
            </fieldset>

            <div class="formGroup">
                <input type="button" class="btn btnPrimary" value="Accedi" (click)="authService.SignIn(userName.value, userPassword.value)">
            </div>
        </div>
    </div>
</div>
